import IAppConfig from "../interfaces/IAppConfig";
import { IGraphPoint } from "../interfaces/IGraph";

export class DataUtils {
	/**
	 * Deep clones an object using JSON serialization and deserialization.
	 * @param obj The object to deep clone.
	 * @returns A deep clone of the provided object.
	 */
	static deepClone<T>(obj: T): T {
		return JSON.parse(JSON.stringify(obj));
	}
	/**
	 * Deep clones an object and excludes specified properties.
	 * @param obj The object to deep clone.
	 * @param excludeKeys An array of property names to exclude.
	 * @returns A deep clone of the object excluding the specified properties.
	 */
	static deepCloneExcluding<T>(obj: T, excludeKeys: (keyof T)[]): T {
		// Create a deep clone of the object
		const cloned = DataUtils.deepClone(obj);

		// Remove specified properties from the clone
		for (const key of excludeKeys) {
			delete (cloned as any)[key];
		}

		return cloned;
	}
	/**
	 * Calculates the percentage change from an old value to a new value.
	 * @param oldValue - The initial value.
	 * @param newValue - The final value.
	 * @returns The percentage change from oldValue to newValue.
	 */
	static calcPercentage(oldValue: number, newValue: number): number {
		if (oldValue === 0) {
			return 0; // or handle as needed to avoid division by zero
		}

		const percentageChange =
			Math.floor(((newValue - oldValue) / oldValue) * 10000) / 100;
		return percentageChange;
	}
	static calcPL(data: IGraphPoint, appConfig: IAppConfig): number {
		return (
			Math.floor(
				(data.exit.ltp - data.entry.ltp) *
					appConfig.lotSize[appConfig.stockKey[data.stock.code]] *
					100,
			) / 100
		);
	}
	static getTradeInfo(pointIndex: number, sortedTrades: any[], yValue = 0) {
		if (sortedTrades.length > 0) {
			const point = sortedTrades[pointIndex];
			const entryPrices = sortedTrades.map((trade) => trade.entry.ltp);
			const maxEntryPrice = Math.max(
				...entryPrices.slice(0, pointIndex + 1),
			);
			const maxEntryPriceMultiplied = maxEntryPrice * 15;

			const profitPercentage = DataUtils.calcPercentage(
				point.entry.ltp,
				point.exit.ltp,
			);
			const calculatedProfit =
				Math.floor(maxEntryPriceMultiplied * yValue) / 100;

			return {
				point,
				maxEntryPriceMultiplied,
				profitPercentage,
				calculatedProfit,
			};
		}
		return null;
	}
}
