import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import AnanlysisDataGrid from "../../components/analysis/AnanlysisDataGrid/AnanlysisDataGrid";
import ImportReportForm from "../../components/analysis/ImportReportForm/ImportReportForm";
import PLGraph from "../../components/analysis/PLGraph/PLGraph";
import StockGraph from "../../components/analysis/StockGraph/StockGraph";
import styles from "./Analysis.module.scss";
import { IResult, IStrategies } from "../../interfaces/IGraph";
import ReportForm from "../../components/analysis/ReportForm/ReportForm";
import StrikeGraph from "../../components/analysis/StrikeGraph/StrikeGraph";

interface TabPanelProps {
	children?: React.ReactNode;
	value: number;
	index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
	return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const Analysis: React.FC = () => {
	const [data, setData] = useState<IResult[]>([{ analysis: [], stocks: [], strikes: [] }]);
	const [tabIndex, setTabIndex] = useState(0);
	const [spotPrice, setSpotPrice] = useState<number>(0);
	const [selectedDataIndex, setSelectedDataIndex] = useState<number | null>(null);
	const [resetFlag, setResetFlag] = useState<boolean>(false);

	useEffect(() => {
		setSelectedDataIndex(null);
	}, [data]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const handleSpotPriceChange = (price: number) => {
		setSpotPrice(price);
	};

	const handleRowSelect = (index: number) => {
		setSelectedDataIndex(index);
	};

	const fetchingFreshData = () => {
		setResetFlag((prev) => !prev);
	};
	const selectedData = selectedDataIndex != null ? data[selectedDataIndex] : null;

	return (
		<div className={styles.container}>
			<Typography variant="h2">Analysis</Typography>
			{/* Tabs for Fetch and Import */}
			<Tabs value={tabIndex} onChange={handleTabChange}>
				<Tab label="Fetch" />
				<Tab label="Import" />
			</Tabs>
			{/* Tab Panels */}
			<TabPanel value={tabIndex} index={0}>
				<ReportForm setData={setData} fetchingFreshData={fetchingFreshData} />
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				{/* Import functionality can go here */}
				<ImportReportForm setData={setData} />
			</TabPanel>
			{/* Common Data Grid */}
			<AnanlysisDataGrid data={data} onRowSelect={handleRowSelect} resetGraph={resetFlag}/>
			<StockGraph data={selectedData?.stocks || []} onSpotPriceChange={handleSpotPriceChange} resetGraph={resetFlag}  />
			<StrikeGraph data={selectedData?.strikes || []} spot_price={spotPrice} resetGraph={resetFlag} />
			<PLGraph data={selectedData?.analysis || []} resetGraph={resetFlag}  />
		</div>
	);
};

export default Analysis;
