import React, { useContext, useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { Box } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { useWebSocket } from "../../contexts/WebSocketContext";

// Define the props interface
interface ConnectivityIndicatorProps {}

const ConnectivityIndicator: React.FC<ConnectivityIndicatorProps> = () => {
	const auth = useContext(AuthContext);
	const { isConnected } = useWebSocket();
	const [showIndicator, setShowIndicator] = useState(false);

	useEffect(() => {
		if (auth?.isAuthenticated) {
			setShowIndicator(true);
		} else {
			setShowIndicator(false);
		}
	}, [auth?.isAuthenticated]);

	// Inline styles for the div
	const iconStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 24,
		height: 24,
		borderRadius: "50%",
		color: isConnected ? "#01fd0b" : "#dc004e",
	};

	return (
		<div hidden={!showIndicator}>
			<Box
				sx={iconStyle}
				title={`Server is ${isConnected ? "connected" : "disconnected"}`}
			>
				{isConnected ? (
					<CheckCircleIcon fontSize="small" />
				) : (
					<UnpublishedIcon fontSize="small" />
				)}
			</Box>
		</div>
	);
};

export default ConnectivityIndicator;
