// App.tsx
import React from "react";
import AppRoutes from "./routes/Routes";
import { ThemeProviderContext } from "./contexts/ThemeProviderContext";
import { LoggingProvider } from "./contexts/LoggingContext";
import { AuthProvider } from "./contexts/AuthContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { StockSubscriptionProvider } from "./contexts/StockSubscriptionContext";
import { TradingProvider } from "./contexts/TradingContext";
import { DataProvider } from "./contexts/DataProvider";

import "./scss/Global.scss";

const App: React.FC = () => {
	return (
		<ThemeProviderContext>
			<LoggingProvider>
				<AuthProvider>
					<WebSocketProvider>
						<StockSubscriptionProvider>
							<TradingProvider>
								<DataProvider>
									<AppRoutes />
								</DataProvider>
							</TradingProvider>
						</StockSubscriptionProvider>
					</WebSocketProvider>
				</AuthProvider>
			</LoggingProvider>
		</ThemeProviderContext>
	);
};

export default App;
