import React, { useContext } from "react";
import { Container, Typography } from "@mui/material";
import Trade from "../components/trade/Trade";
import { AuthContext } from "../contexts/AuthContext";
import config from "../config/config";
import Login from "./Login";

const Home: React.FC = () => {
	const auth = useContext(AuthContext);

	return (
		<div>
			{!auth?.isVendorAuthenticated ? (
				<Typography variant="h6" align="center" color="textSecondary">
					Please log in using the <a href={config.vendor.icici}>vendor link</a>.
				</Typography>
			) : !auth?.isAuthenticated ? (
				<Login />
			) : (
				<Container maxWidth={false} disableGutters>
					<Trade />
				</Container>
			)}
		</div>
	);
};

export default Home;
