import React, { useState, useEffect } from "react";
import { useLogger } from "../../contexts/LoggingContext";
import { useTheme } from "@mui/material/styles";
import styles from "./Logger.module.scss";
import { StringUtils } from "../../utils/StringUtils";

const Logger: React.FC = () => {
	const { logs, setShowLogger, clearLogs } = useLogger();
	const theme = useTheme();
	const [isDragging, setIsDragging] = useState(false);
	const [offset, setOffset] = useState<{ x: number; y: number }>({
		x: 5,
		y: 70,
	});
	const [startPosition, setStartPosition] = useState<{
		x: number;
		y: number;
	}>({ x: 0, y: 0 });

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsDragging(true);
		setStartPosition({
			x: event.clientX - offset.x,
			y: event.clientY - offset.y,
		});
	};

	const handleMouseMove = (event: MouseEvent) => {
		if (isDragging) {
			setOffset({
				x: event.clientX - startPosition.x,
				y: event.clientY - startPosition.y,
			});
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
		localStorage.setItem("loggerPosition", JSON.stringify(offset));
	};

	const handleClose = () => {
		setShowLogger(false);
	};

	const handleClear = () => {
		clearLogs();
	};

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [isDragging, startPosition, offset]);

	useEffect(() => {
		const savedPosition = localStorage.getItem("loggerPosition");
		if (savedPosition) {
			setOffset(JSON.parse(savedPosition));
		}
	}, []);

	const loggerStyle = {
		top: `${offset.y}px`,
		left: `${offset.x}px`,
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#fff",
		boxShadow:
			theme.palette.mode === "dark"
				? "0 2px 5px rgba(255, 255, 255, 0.2)"
				: "0 2px 5px rgba(0, 0, 0, 0.2)",
	};

	return (
		<div
			className={styles.loggerContainer}
			style={loggerStyle}
			onMouseDown={handleMouseDown}
		>
			<div className={styles.header}>
				<strong>Logger</strong>
				<div className={styles.buttons}>
					<button
						onClick={handleClear}
						className={styles.clearButton}
					>
						Clear
					</button>
					<button
						className={styles.closeButton}
						onClick={handleClose}
					>
						X
					</button>
				</div>
			</div>
			<div className={styles.logContent}>
				{[...logs].reverse().map((log, index) => (
					<div
						key={index}
						className={
							StringUtils.hasError(log.message)
								? styles.error
								: ""
						}
					>
						<strong>{log.time}: </strong>
						{log.message}
					</div>
				))}
			</div>
		</div>
	);
};

export default Logger;
