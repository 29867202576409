import React, { useEffect, useState } from "react";
import { Button, Select, MenuItem, FormControl, InputLabel, Typography, FormControlLabel, Switch } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { useWebSocket } from "../../contexts/WebSocketContext";
import { useLogger } from "../../contexts/LoggingContext";
import { useStockSubscription } from "../../contexts/StockSubscriptionContext";
import { useData } from "../../contexts/DataProvider";
import { useTrading } from "../../contexts/TradingContext";
import { useTheme } from "../../contexts/ThemeProviderContext";

import { MessageType, ThemeType, TradeType } from "../../config/config";

import { IStockDataPoint, IStrikeData } from "../../interfaces/IGraph";

import StockGraph from "../analysis/StockGraph/StockGraph";
import StrikeGraph from "../analysis/StrikeGraph/StrikeGraph";

import styles from "./Trade.module.scss";

const Trade: React.FC = () => {
	const { themeMode } = useTheme();
	const { receiveMessage, isConnected } = useWebSocket();
	const { isSubscribed, stockCode, subscribeStock, unsubscribeStock } = useStockSubscription();
	const { isTrading, tradeMode, tradeModeChange, tradeStart, tradeEnd } = useTrading();
	const { addLog } = useLogger();
	const { appConfig } = useData();

	const [selectedStockCode, setSelectedStockCode] = useState<string>(stockCode || "");
	const [localTrading, setLocalTrading] = useState<boolean>(false);
	const [localTradeMode, setLocalTradeMode] = useState<string>(tradeMode);
	const [stocks, setStocks] = useState<IStockDataPoint[]>([]);
	const [strikes, setStrikes] = useState<IStrikeData[]>([]);
	const [spotPrice, setSpotPrice] = useState<number>(0); // State for spot_price

	useEffect(() => {
		receiveMessage((message) => {
			switch (message.type) {
				case MessageType.liveStock:
					// TODO: handle live stock message
					break;
				case MessageType.subscribedStock:
					message.data?.stocks && setStocks(message.data.stocks);
					message.data?.strikes && setStrikes(message.data.strikes);
					break;
			}
		});
	}, [receiveMessage]);

	useEffect(() => {
		setLocalTrading(isTrading);
	}, [isTrading]);

	useEffect(() => {
		setLocalTradeMode(tradeMode);
	}, [tradeMode]);

	const handleStockCodeChange = (event: SelectChangeEvent<string>) => {
		setSelectedStockCode(event.target.value as string);
	};

	const handleTradeStart = () => {
		if (selectedStockCode) {
			if (isSubscribed) {
				stockUnsubscribe();
			} else {
				stockSubscribe();
			}
		}
	};

	const stockUnsubscribe = () => {
		if (isSubscribed) {
			unsubscribeStock();
			addLog(`↥ Requested unsubscription for stock code: ${selectedStockCode}`);
		}
	};

	const stockSubscribe = () => {
		if (!isSubscribed) {
			subscribeStock(selectedStockCode);
			addLog(`↥ Requested subscription for stock code: ${selectedStockCode}`);
		}
	};

	const togglePaperMode = () => {
		const isPaperMode = localTradeMode === TradeType.paper;
		setLocalTradeMode(isPaperMode ? TradeType.cash : TradeType.paper);
		tradeModeChange(isPaperMode ? TradeType.cash : TradeType.paper);
	};

	const toggleTrading = () => {
		if (isTrading) {
			setLocalTrading(false);
			tradeEnd();
		} else {
			setLocalTrading(true);
			tradeStart(localTradeMode);
		}
	};

	const handleSpotPriceChange = (price: number) => {
		setSpotPrice(price);
	};

	return (
		<div className={styles["trade-root"]}>
			<Typography variant="h2">Trade {isSubscribed && ` ${stockCode}`}</Typography>
			{isConnected && appConfig.stockCode && (
				<>
					<div className={styles.header}>
						<div className={styles.wsTradeControls}>
							<FormControl fullWidth className={styles.select}>
								<InputLabel id="stock-code-label" size="small">
									Select Stock Code
								</InputLabel>
								<Select labelId="stock-code-label" value={selectedStockCode} onChange={handleStockCodeChange} size="small" disabled={isSubscribed} fullWidth>
									{Object.entries(appConfig.stockCode).map(([key, value]) => (
										<MenuItem key={key} value={key as string}>
											{`${key} (${value})`}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<Button variant="contained" color="primary" onClick={handleTradeStart} disabled={!selectedStockCode} className={styles.button}>
								{isSubscribed ? "Unsubscribe" : "Subscribe"} Stock
							</Button>
						</div>

						<div className={styles["trade-holder"]}>
							<FormControlLabel control={<Switch checked={localTrading} onChange={toggleTrading} disabled={!isSubscribed || !isConnected} color="primary" />} label={`Trading`} />
							<div className={`${styles.switch} ${!isSubscribed || !isConnected || !isTrading ? "disabled" : ""}`}>
								<div className={`${themeMode === ThemeType.dark ? "dark" : "light"}`}>Paper</div>
								<FormControlLabel control={<Switch checked={localTradeMode === TradeType.cash} onChange={togglePaperMode} disabled={!isSubscribed || !isConnected || !isTrading} color="primary" />} label="" labelPlacement="start" />
								<div className={`${themeMode === ThemeType.dark ? "dark" : "light"}`}>Cash</div>
							</div>
						</div>
					</div>
					<StockGraph data={stocks} onSpotPriceChange={handleSpotPriceChange} />
					<StrikeGraph data={strikes} spot_price={spotPrice} />
				</>
			)}
		</div>
	);
};

export default Trade;
