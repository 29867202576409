import React, { useEffect, useState, useRef } from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Box } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { useWebSocket } from "../../../contexts/WebSocketContext";
import { useStockSubscription } from "../../../contexts/StockSubscriptionContext";
import { MessageType } from "../../../config/config";
import styles from "./SubscriptionIndicator.module.scss";

const SubscriptionIndicator: React.FC = () => {
	const { isAuthenticated } = useAuth();
	const { isSubscribed } = useStockSubscription();
	const { isConnected, receiveMessage } = useWebSocket();

	const [showIndicator, setShowIndicator] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [currentStockClose, setCurrentStockClose] = useState(0);
	const prevStockClose = useRef<number | null>(null);

	useEffect(() => {
		setShowIndicator(isAuthenticated && isConnected && isSubscribed);
	}, [isAuthenticated, isConnected, isSubscribed]);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		let timeoutId: NodeJS.Timeout;

		if (isConnected && isSubscribed) {
			receiveMessage((message) => {
				const isLiveStockFeed = message.type === MessageType.liveStock;
				if (isLiveStockFeed) {
					if (isLiveStockFeed) {
						// Store the previous value before updating
						prevStockClose.current = currentStockClose;
						setCurrentStockClose(Math.round(message.data.close));
					}

					setIsActive(true);
					clearTimeout(timeoutId);

					timeoutId = setTimeout(() => {
						setIsActive(false);
					}, 2000);
				}
			});
		}

		return () => clearTimeout(timeoutId);
	}, [receiveMessage, isConnected, isSubscribed]);

	// Determine the class based on the change in stock close value
	const stockCloseClass = prevStockClose.current === null ? styles.neutral : currentStockClose > prevStockClose.current ? styles.increased : currentStockClose < prevStockClose.current ? styles.decreased : styles.neutral;

	return showIndicator ? (
		<div className={`${styles["subscription-indicator"]} ${isActive ? "active" : ""}`}>
			<Box className={styles.icon} title={isActive ? "Subscription live! The market's talking, and we're listening." : "Subscription on a coffee break. We'll be right back!"}>
				{isActive ? <TimelineIcon fontSize="small" className={styles.increased} /> : <ShowChartIcon fontSize="small" className={styles.decreased} />}
			</Box>
			<div className={stockCloseClass}>{currentStockClose}</div>
		</div>
	) : null;
};

export default SubscriptionIndicator;
