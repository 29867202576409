import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";
import moment from "moment";
import styles from "./StockGraph.module.scss";
import { IStockDataPoint } from "../../../interfaces/IGraph";
import { useTheme } from "../../../contexts/ThemeProviderContext";
import { useData } from "../../../contexts/DataProvider";
import { ThemeType } from "../../../config/config";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

interface StockGraphProps {
	data: IStockDataPoint[];
	onSpotPriceChange?: (price: number) => void;
	resetGraph?: boolean;
}

const StockGraph: React.FC<StockGraphProps> = ({ data, onSpotPriceChange, resetGraph }) => {
	const { themeMode } = useTheme();
	const { appConfig } = useData();
	const [chartData, setChartData] = useState<any>(null);
	const [sortedData, setSortedData] = useState<IStockDataPoint[]>([]);

	const [startingQuarterInterval, setStartingQuarterInterval] = useState<number>(0);
	const [endingQuarterInterval, setEndingQuarterInterval] = useState<number>(0);
	const [lastReportedPrice, setLastReportedPrice] = useState<number | null>(null); // Add this line

	useEffect(() => {
		setChartData(null);
		setSortedData([]);
		setStartingQuarterInterval(0);
		setEndingQuarterInterval(0);
		setLastReportedPrice(null);
	}, [resetGraph]);

	useEffect(() => {
		if (!data || data.length === 0) return;

		// Sort data by datetime
		const sorted = [...data].sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());
		setSortedData(sorted);

		// Get the first and last data point time
		const firstTime = new Date(sorted[0].datetime);
		const lastTime = new Date(sorted[sorted.length - 1].datetime);

		// Calculate the nearest multiple of 15 minutes for the first and last data points
		setStartingQuarterInterval(new Date(Math.floor(firstTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());
		setEndingQuarterInterval(new Date(Math.ceil(lastTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());

		// Prepare the chart data with stock close prices
		const labels = sorted.map((point) => point.datetime);
		const closeValues = sorted.map((point) => point.close);

		// Define the time threshold in seconds
		const timeThreshold = 15;

		// Function to check and return the border color dynamically
		const checkBorderColor = (ctx: { p1DataIndex: number; p0DataIndex: number }) => {
			const currentIndex = ctx.p1DataIndex;
			const previousIndex = ctx.p0DataIndex;

			// Ensure we have valid indexes
			if (currentIndex >= 0 && previousIndex >= 0) {
				const currentTime = new Date(sorted[currentIndex].datetime).getTime();
				const previousTime = new Date(sorted[previousIndex].datetime).getTime();

				// Calculate the time difference in seconds
				const timeDiffInSeconds = (currentTime - previousTime) / 1000;

				// Return red if the time difference is greater than the threshold, otherwise blue
				return timeDiffInSeconds > timeThreshold ? "#FF0000" : "#4A90E2";
			}
			return "#4A90E2"; // Default color
		};

		setChartData({
			labels,
			datasets: [
				{
					label: "Stock Spot Price",
					data: closeValues,
					borderColor: "#4A90E2", // Default color for the entire line
					fill: false,
					tension: 0.1,
					pointRadius: 0,
					borderWidth: 1,
					segment: {
						borderColor: (ctx: { p1DataIndex: number; p0DataIndex: number }) => checkBorderColor(ctx), // Dynamically set the segment color
					},
				},
			],
		});
	}, [data]);

	// Format the start and end dates
	const startDate = moment(sortedData[0]?.datetime).format("DD-MMM hh:mm");
	const endDate = moment(sortedData[sortedData.length - 1]?.datetime).format("DD-MMM hh:mm");

	return (
		<div className={styles["stock-graph"]}>
			{/* Chart header showing start and end date */}
			{chartData && <div className={styles["chart-header"]}>{`Stock Prices from ${startDate} to ${endDate}`}</div>}
			{chartData && (
				<Line
					className={styles["graph"]}
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								type: "time",
								min: startingQuarterInterval, // Set the minimum to the nearest 15-minute mark
								max: endingQuarterInterval, // Set the maximum to the nearest 15-minute mark
								time: {
									unit: "minute",
									tooltipFormat: appConfig?.timeFormats?.shortHm || "HH:mm",
									displayFormats: {
										minute: appConfig?.timeFormats?.shortHm || "HH:mm",
									},
								},
								title: {
									display: true,
									text: "Time",
								},
								ticks: {
									stepSize: 15,
									source: "auto",
									autoSkip: true,
									callback: function (value) {
										const date = moment(value);
										return date.format(appConfig?.timeFormats?.shortHm || "HH:mm");
									},
								},
								grid: {
									color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
								},
							},
							y: {
								title: {
									display: true,
									text: "Spot Price",
								},
								grid: {
									color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
								},
							},
						},
						plugins: {
							legend: {
								display: true,
								position: "top",
							},
							tooltip: {
								callbacks: {
									label: (tooltipItem) => {
										const price: number = Number(tooltipItem.raw);
										if (onSpotPriceChange) {
											const nearestHundred = Math.round(price / 100) * 100; // Round to nearest hundred

											// Check if the price crosses the nearest hundred compared to the last reported price
											if (lastReportedPrice === null || (lastReportedPrice < nearestHundred && price >= nearestHundred) || (lastReportedPrice > nearestHundred && price <= nearestHundred)) {
												onSpotPriceChange(price); // Update spot price
												setLastReportedPrice(price); // Update the last reported price
											}
										}
										return `Spot Price: ₹${price}`;
									},
								},
							},
						},
					}}
				/>
			)}
		</div>
	);
};

export default StockGraph;
