import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from "@mui/material";
import { useWebSocket } from "../../../contexts/WebSocketContext";
import { clientRequestType, MessageType } from "../../../config/config";
import styles from "./FundsManagerDialog.module.scss";

interface FundsManagerDialogProps {
	open: boolean;
	onClose: () => void;
}

const FundsManagerDialog: React.FC<FundsManagerDialogProps> = ({ open, onClose }) => {
	const { sendMessage, receiveMessage, unsubscribeMessage, isConnected } = useWebSocket();
	const [amount, setAmount] = useState<number>(0);
	const [transactionType, setTransactionType] = useState<string>("credit");
	const [segment, setSegment] = useState<string>("Equity");
	const [fundsData, setFundsData] = useState<any>(null);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);

	useEffect(() => {
		if (!isConnected) {
			onClose();
		}
	}, [isConnected]);

	const handleSubmit = () => {
		// Send the message with amount, transaction type, and segment
		sendMessage({
			type: clientRequestType.fundsSet,
			data: {
				amount: amount,
				type: transactionType,
				segment: segment,
			},
		});
	};

	useEffect(() => {
		// Only request funds data if it's not already loaded
		if (open && !fundsData) {
			sendMessage({
				type: clientRequestType.fundsGet,
			});
		}
	}, [open, sendMessage, fundsData]);

	useEffect(() => {
		const handleFundsMessage = (message: any) => {
			if (message.type === MessageType.funds) {
				const { funds } = message.data;
				setFundsData(funds);
			}
		};

		// Set up the WebSocket listener when the component mounts
		receiveMessage(handleFundsMessage);

		// Clean up the listener when the component unmounts
		return () => {
			unsubscribeMessage(handleFundsMessage);
		};
	}, [receiveMessage, unsubscribeMessage]);

	useEffect(() => {
		// Enable submit button based on the received funds data
		if (fundsData) {
			const unallocatedBalance = parseFloat(fundsData.unallocated_balance);
			const allocatedEquity = fundsData.allocated_equity;
			const allocatedFno = fundsData.allocated_fno;

			if (transactionType === "credit") {
				// Enable submit if unallocated balance is greater than 0 and amount <= unallocated_balance
				setIsSubmitEnabled(unallocatedBalance > 0 && amount <= unallocatedBalance);
			} else if (transactionType === "debit") {
				// Enable submit if allocated equity or FNO is greater than 0 and amount <= allocated equity/FNO
				if (segment === "Equity") {
					setIsSubmitEnabled(allocatedEquity > 0 && amount <= allocatedEquity);
				} else if (segment === "FNO") {
					setIsSubmitEnabled(allocatedFno > 0 && amount <= allocatedFno);
				}
			}
		}
	}, [fundsData, transactionType, segment, amount]);

	return (
		<Dialog open={open} onClose={onClose} className={styles["funds-manager"]}>
			<DialogTitle>Funds Manager</DialogTitle>
			<DialogContent>
				{fundsData ? (
					<div>
						<div className={styles["funds-result"]}>
							<div>
								Unallocated Balance <span>₹ {fundsData.unallocated_balance}</span>
							</div>
							<div>
								Allocated Equity <span>₹ {fundsData.allocated_equity}</span>
							</div>
							<div>
								Allocated FNO <span>₹ {fundsData.allocated_fno}</span>
							</div>
						</div>
						<FormControl fullWidth margin="normal">
							<TextField label="Amount" type="number" value={amount} onChange={(e) => setAmount(Number(e.target.value))} fullWidth />
						</FormControl>
						<FormControl fullWidth margin="normal">
							<InputLabel>Transaction Type</InputLabel>
							<Select value={transactionType} onChange={(e) => setTransactionType(e.target.value as string)}>
								<MenuItem value="credit">Credit</MenuItem>
								<MenuItem value="debit">Debit</MenuItem>
							</Select>
						</FormControl>
						<FormControl fullWidth margin="normal">
							<InputLabel>Segment</InputLabel>
							<Select value={segment} onChange={(e) => setSegment(e.target.value as string)}>
								<MenuItem value="Equity">Equity</MenuItem>
								<MenuItem value="FNO">FNO</MenuItem>
							</Select>
						</FormControl>
					</div>
				) : (
					<Typography>Loading funds data...</Typography>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmit} disabled={!isSubmitEnabled}>
					Submit
				</Button>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FundsManagerDialog;
