import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useWebSocket } from "./WebSocketContext";
import { clientRequestType, MessageType } from "../config/config";

interface StockSubscriptionContextType {
	isSubscribed: boolean;
	stockCode: string | null;
	// eslint-disable-next-line no-unused-vars
	subscribeStock: (stockCode: string) => void;
	unsubscribeStock: () => void;
}

const StockSubscriptionContext = createContext<
	StockSubscriptionContextType | undefined
>(undefined);

export const StockSubscriptionProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { isConnected, sendMessage, receiveMessage } = useWebSocket();
	const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
	const [stockCode, setStockCode] = useState<string | null>(null);

	useEffect(() => {
		receiveMessage((message) => {
			switch (message.type) {
				case MessageType.subscribedStock:
					setIsSubscribed(true);
					setStockCode(message.data.code);
					break;
				case MessageType.unsubscribedStock:
					setIsSubscribed(false);
					setStockCode(null);
					break;
			}
		});
	}, [receiveMessage]);

	const subscribeStock = useCallback(
		(code: string) => {
			if (isConnected) {
				sendMessage({
					type: clientRequestType.subscribeStock,
					data: { key: code },
				});
			}
		},
		[isConnected, sendMessage],
	);

	const unsubscribeStock = useCallback(() => {
		if (isConnected && stockCode) {
			sendMessage({
				type: clientRequestType.unsubscribeStock,
				data: { key: stockCode },
			});
		}
	}, [isConnected, sendMessage, stockCode]);

	return (
		<StockSubscriptionContext.Provider
			value={{
				isSubscribed,
				stockCode,
				subscribeStock,
				unsubscribeStock,
			}}
		>
			{children}
		</StockSubscriptionContext.Provider>
	);
};

export const useStockSubscription = () => {
	const context = useContext(StockSubscriptionContext);
	if (context === undefined) {
		throw new Error(
			"useStockSubscription must be used within a StockSubscriptionProvider",
		);
	}
	return context;
};
