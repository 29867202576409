import { createTheme } from "@mui/material/styles";

// Define color constants
export const Colors = {
	dark: {
		gridLine: "#ffffff",
		tick: "#ffffff",
		legend: "#ffffff",
	},
	light: {
		gridLine: "#000000",
		tick: "#000000",
		legend: "#000000",
	},
	status: {
		red: "#dc004e",
		green: "#01fd0b",
	},
};

// Common theme properties
const commonTheme = {
	palette: {
		primary: {
			main: "#1976d2", // Customize primary color
		},
		secondary: {
			main: "#dc004e", // Customize secondary color
		},
		text: {
			primary: Colors.light.tick, // Default text color for light theme
			secondary: Colors.light.tick, // Default secondary text color for light theme
		},
		background: {
			default: "#fff", // Default background color for light theme
		},
	},
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		h1: {
			fontSize: "1.5rem", // Small font size for headings
		},
		h2: {
			fontSize: "1.25rem",
			// marginTop: '0px',
		},
		h3: {
			fontSize: "1rem",
		},
		body1: {
			fontSize: "0.875rem", // Small font size for body text
		},
		body2: {
			fontSize: "0.75rem",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					padding: "4px 8px", // Compact button size
					fontSize: "0.75rem",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					margin: "8px 0", // Compact spacing
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				maxWidthSm: {
					maxWidth: "360px", // Smaller container width for sleek design
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					"@media (min-width: 0px) and (orientation: landscape)": {
						minHeight: "36px",
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: Colors.light.legend,
					background: "#888",
					"&.Mui-selected": {
						color: "rgba(255, 255, 255, 0.8)",
						background: "#777",
						cursor: "auto",
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					maxWidth: "800px",
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				cell: {
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				},
			},
		},
	},
};

// Light theme
export const lightTheme = createTheme({
	...commonTheme,
	palette: {
		...commonTheme.palette,
		mode: "light",
		text: {
			primary: Colors.light.tick,
			secondary: Colors.light.tick,
		},
		background: {
			default: "#fff",
		},
	},
});

// Dark theme
export const darkTheme = createTheme({
	...commonTheme,
	palette: {
		...commonTheme.palette,
		mode: "dark",
		text: {
			primary: Colors.dark.tick,
			secondary: Colors.dark.tick,
		},
		background: {
			default: "#121212", // Default background color for dark theme
		},
	},
});
