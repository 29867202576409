import React, { createContext, useContext, useState, useEffect } from "react";
import { StringUtils } from "../utils/StringUtils";

interface Log {
	message: string;
	time: string;
}

interface LoggingContextProps {
	logs: Log[];
	// eslint-disable-next-line no-unused-vars
	addLog: (message: string) => void;
	clearLogs: () => void;
	showLogger: boolean;
	// eslint-disable-next-line no-unused-vars
	setShowLogger: (show: boolean) => void;
}

const LoggingContext = createContext<LoggingContextProps | undefined>(
	undefined,
);

export const LoggingProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [logs, setLogs] = useState<Log[]>([]);
	const [showLogger, setShowLogger] = useState<boolean>(() => {
		// Get initial value from local storage
		const saved = localStorage.getItem("showLogger");
		return saved ? JSON.parse(saved) : true; // Default to true if not found
	});

	useEffect(() => {
		// Save to local storage whenever showLogger changes
		localStorage.setItem("showLogger", JSON.stringify(showLogger));
	}, [showLogger]);

	// Check the latest log for errors and show logger if there's an error
	useEffect(() => {
		if (logs.length > 0) {
			const latestLog = logs[logs.length - 1].message;
			if (StringUtils.hasError(latestLog)) {
				setShowLogger(true);
			}
		}
	}, [logs]);

	const addLog = (message: string) => {
		const time = new Date().toLocaleTimeString();
		setLogs((prevLogs) => [...prevLogs, { message, time }]);
	};

	const clearLogs = () => {
		setLogs([]);
	};

	return (
		<LoggingContext.Provider
			value={{ logs, addLog, clearLogs, showLogger, setShowLogger }}
		>
			{children}
		</LoggingContext.Provider>
	);
};

export const useLogger = () => {
	const context = useContext(LoggingContext);
	if (!context) {
		throw new Error("useLogger must be used within a LoggingProvider");
	}
	return context;
};
