// contexts/ThemeContext.tsx
import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from "react";
import { darkTheme, lightTheme } from "../theme/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { ThemeType } from "../config/config";

interface ThemeContextType {
	themeMode: ThemeType.light | ThemeType.dark;
	toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProviderContext = ({ children }: { children: ReactNode }) => {
	const [themeMode, setThemeMode] = useState<
		ThemeType.light | ThemeType.dark
	>(ThemeType.dark);

	useEffect(() => {
		const savedTheme = localStorage.getItem("themeMode") as
			| ThemeType.light
			| ThemeType.dark
			| null;
		if (savedTheme) {
			setThemeMode(savedTheme);
		}
	}, []);

	const toggleTheme = () => {
		const newThemeMode =
			themeMode === ThemeType.light ? ThemeType.dark : ThemeType.light;
		setThemeMode(newThemeMode);
		localStorage.setItem("themeMode", newThemeMode.toString());
	};

	const theme = themeMode === ThemeType.light ? lightTheme : darkTheme;

	return (
		<ThemeContext.Provider value={{ themeMode, toggleTheme }}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useTheme = (): ThemeContextType => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error(
			"useTheme must be used within a ThemeProviderComponent",
		);
	}
	return context;
};
