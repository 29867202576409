import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DataGrid, GridCellParams, GridColDef, useGridApiRef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import styles from "./AnanlysisDataGrid.module.scss";
import moment from "moment";
import * as XLSX from "xlsx";
import { fileSave } from "browser-fs-access";

interface AnanlysisDataGridProps {
	data: Array<any>;
	onRowSelect: (index: number) => void;
	resetGraph?: boolean;
}

const AnanlysisDataGrid: React.FC<AnanlysisDataGridProps> = ({ data, onRowSelect, resetGraph }) => {
	const [pageSize] = React.useState<number>(5);
	const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);
	const [rows, setRows] = useState<any[]>([]); // Define rows state here
	const apiRef = useGridApiRef();

	useEffect(() => {
		// Reset selected row when resetGraph changes
		setSelectedRowId(null);

		// Reset rows when resetGraph changes/data is set
		setRows([]); // Clear rows
	}, [resetGraph]); // Add data to dependencies

	useEffect(() => {
		// Populate rows from data
		setRows(
			data.flatMap((item, dataIndex) =>
				item.analysis.map(
					(
						analysisItem: {
							start: string | Date;
							end: string | Date;
							plStatement: {
								strategy: { continuity: any; priceHikePercentage: any; tradeStopLoss: any };
								result: {
									pl: { net: any; percentage: any; maxPL: any; maxPLPercentage: any; minPL: any; minPLPercentage: any };
									profit: { lowest: any; highest: any; average: any; total: any; count: any };
									loss: { lowest: any; highest: any; average: any; total: any; count: any };
								};
								price: { entry: { lowest: any; highest: any; average: any }; exit: { lowest: any; highest: any; average: any } };
							};
						},
						analysisIndex: number
					) => ({
						id: `${dataIndex + 1}-${analysisIndex + 1}`,
						startFormatted: formatDateTime(analysisItem.start),
						endFormatted: formatDateTime(analysisItem.end),
						continuity: analysisItem.plStatement.strategy.continuity,
						priceHikePercentage: analysisItem.plStatement.strategy.priceHikePercentage,
						stopLoss: analysisItem.plStatement.strategy.tradeStopLoss,
						net: analysisItem.plStatement.result.pl.net,
						percentage: analysisItem.plStatement.result.pl.percentage,
						maxPL: analysisItem.plStatement.result.pl.maxPL,
						maxPLPercentage: analysisItem.plStatement.result.pl.maxPLPercentage,
						minPL: analysisItem.plStatement.result.pl.minPL,
						minPLPercentage: analysisItem.plStatement.result.pl.minPLPercentage,
						profitLowest: analysisItem.plStatement.result.profit.lowest,
						profitHighest: analysisItem.plStatement.result.profit.highest,
						profitAverage: analysisItem.plStatement.result.profit.average,
						profitTotal: analysisItem.plStatement.result.profit.total,
						profitCount: analysisItem.plStatement.result.profit.count,
						lossLowest: analysisItem.plStatement.result.loss.lowest,
						lossHighest: analysisItem.plStatement.result.loss.highest,
						lossAverage: analysisItem.plStatement.result.loss.average,
						lossTotal: analysisItem.plStatement.result.loss.total,
						lossCount: analysisItem.plStatement.result.loss.count,
						priceEntryLowest: analysisItem.plStatement.price.entry.lowest,
						priceEntryHighest: analysisItem.plStatement.price.entry.highest,
						priceEntryAverage: analysisItem.plStatement.price.entry.average,
						priceExitLowest: analysisItem.plStatement.price.exit.lowest,
						priceExitHighest: analysisItem.plStatement.price.exit.highest,
						priceExitAverage: analysisItem.plStatement.price.exit.average,
					})
				)
			)
		);
	}, [data]); // Add data to dependencies

	// Column visibility state
	const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
		id: false,
		profitLowest: false,
		profitHighest: false,
		profitAverage: false,

		lossLowest: false,
		lossHighest: false,
		lossAverage: false,

		priceEntryLowest: false,
		priceEntryAverage: false,
		priceExitLowest: false,
		priceExitHighest: false,
		priceExitAverage: false,
	});

	const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

	const exportToExcel = async () => {
		const api = apiRef.current;

		if (!api || rows.length === 0) {
			alert("No data available to export.");
			return;
		}

		// Get the visible rows
		const visibleRows = Array.from(api.getRowModels().values());

		const columnFields = api.getAllColumns().map((col) => col.field);

		const rowsForExport = visibleRows.map((row) => {
			const rowData: { [key: string]: any } = {};

			columnFields.forEach((field) => {
				if (columnVisibilityModel[field] !== false) {
					rowData[field] = row[field]; // Collect visible column data for each row
				}
			});

			return rowData;
		});

		// Extract start and end dates
		const startDates = visibleRows.map((row) => new Date(row.startFormatted).getTime()); // Convert to timestamp
		const endDates = visibleRows.map((row) => new Date(row.endFormatted).getTime()); // Convert to timestamp

		const oldestStart = new Date(Math.min(...startDates));
		const latestEnd = new Date(Math.max(...endDates));

		// Create the file name
		const fileName = generateFileName(oldestStart, latestEnd);

		const ws = XLSX.utils.json_to_sheet(rowsForExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });

		// Use fileSave with the correct file name
		await fileSave(dataBlob, {
			fileName: fileName, // Use the dynamic file name
			extensions: [".xlsx"],
			mimeTypes: [EXCEL_TYPE],
		});
	};

	// Function to generate file name based on dates
	const generateFileName = (startDate: Date, endDate: Date): string => {
		const formattedStart = formatDateTime(startDate);
		const formattedEnd = formatDateTime(endDate);

		return `report-${formattedStart}-${formattedEnd}.xlsx`;
	};

	const formatDateTime = (dateString: string | Date) => {
		return moment(dateString).format("DD-MMM HH:mm");
	};

	const handleRowClick = (row: any) => {
		setSelectedRowId(row.id);
		const [dataIndex] = row.id.split("-");
		onRowSelect(Number(dataIndex) - 1);
	};

	// const rows = data.flatMap((item, dataIndex) =>
	// 	item.analysis.map(
	// 		(
	// 			analysisItem: {
	// 				start: string | Date;
	// 				end: string | Date;
	// 				plStatement: {
	// 					strategy: { continuity: any; priceHikePercentage: any; tradeStopLoss: any };
	// 					result: { pl: { net: any; percentage: any; maxPL: any; maxPLPercentage: any; minPL: any; minPLPercentage: any }; profit: { lowest: any; highest: any; average: any; total: any; count: any }; loss: { lowest: any; highest: any; average: any; total: any; count: any } };
	// 					price: { entry: { lowest: any; highest: any; average: any }; exit: { lowest: any; highest: any; average: any } };
	// 				};
	// 			},
	// 			analysisIndex: number
	// 		) => ({
	// 			id: `${dataIndex + 1}-${analysisIndex + 1}`,
	// 			startFormatted: formatDateTime(analysisItem.start),
	// 			endFormatted: formatDateTime(analysisItem.end),
	// 			continuity: analysisItem.plStatement.strategy.continuity,
	// 			priceHikePercentage: analysisItem.plStatement.strategy.priceHikePercentage,
	// 			stopLoss: analysisItem.plStatement.strategy.tradeStopLoss,
	// 			net: analysisItem.plStatement.result.pl.net,
	// 			percentage: analysisItem.plStatement.result.pl.percentage,
	// 			maxPL: analysisItem.plStatement.result.pl.maxPL,
	// 			maxPLPercentage: analysisItem.plStatement.result.pl.maxPLPercentage,
	// 			minPL: analysisItem.plStatement.result.pl.minPL,
	// 			minPLPercentage: analysisItem.plStatement.result.pl.minPLPercentage,
	// 			profitLowest: analysisItem.plStatement.result.profit.lowest,
	// 			profitHighest: analysisItem.plStatement.result.profit.highest,
	// 			profitAverage: analysisItem.plStatement.result.profit.average,
	// 			profitTotal: analysisItem.plStatement.result.profit.total,
	// 			profitCount: analysisItem.plStatement.result.profit.count,
	// 			lossLowest: analysisItem.plStatement.result.loss.lowest,
	// 			lossHighest: analysisItem.plStatement.result.loss.highest,
	// 			lossAverage: analysisItem.plStatement.result.loss.average,
	// 			lossTotal: analysisItem.plStatement.result.loss.total,
	// 			lossCount: analysisItem.plStatement.result.loss.count,
	// 			priceEntryLowest: analysisItem.plStatement.price.entry.lowest,
	// 			priceEntryHighest: analysisItem.plStatement.price.entry.highest,
	// 			priceEntryAverage: analysisItem.plStatement.price.entry.average,
	// 			priceExitLowest: analysisItem.plStatement.price.exit.lowest,
	// 			priceExitHighest: analysisItem.plStatement.price.exit.highest,
	// 			priceExitAverage: analysisItem.plStatement.price.exit.average,
	// 		})
	// 	)
	// );

	const percentageFormatting = (value?: number) => {
		if (value == null) {
			return 0;
		}
		return parseFloat(value.toString().replace("%", "").replace(" ", ""));
	};

	const moneyFormatting = (value?: number) => {
		if (value == null) {
			return "";
		}
		return `₹ ${value.toLocaleString()}`;
	};

	const getFormatClassName = (params: GridCellParams) => {
		const value = params.value as number;
		if (value == null) {
			return "";
		}
		return value >= 0 ? styles.positive : styles.negative;
	};

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 50 },
		{
			field: "startFormatted",
			headerName: "Start Time",
			width: 100,
		},
		{
			field: "endFormatted",
			headerName: "End Time",
			width: 100,
		},
		{ field: "continuity", headerName: "Continuity Count", width: 120 },
		{
			field: "priceHikePercentage",
			headerName: "Price Hike (%)",
			width: 100,
			valueFormatter: percentageFormatting,
		},
		{
			field: "stopLoss",
			headerName: "Stop Loss (%)",
			width: 100,
			valueFormatter: percentageFormatting,
		},
		{
			field: "priceEntryHighest",
			headerName: "Max Investment",
			width: 120,
			valueFormatter: moneyFormatting,
		},
		{
			field: "net",
			headerName: "Net PL",
			width: 100,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "percentage",
			headerName: "Net PL (%)",
			width: 80,
			valueFormatter: percentageFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "maxPL",
			headerName: "Max cumulative PL",
			width: 130,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "maxPLPercentage",
			headerName: "Max cumulative PL (%)",
			width: 150,
			valueFormatter: percentageFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "minPL",
			headerName: "Min cumulative PL",
			width: 130,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "minPLPercentage",
			headerName: "Min cumulative PL (%)",
			width: 150,
			valueFormatter: percentageFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "profitLowest",
			headerName: "Profit Lowest",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "profitHighest",
			headerName: "Profit Highest",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "profitAverage",
			headerName: "Profit Average",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "profitTotal",
			headerName: "Profit Total (in ₹)",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "profitCount",
			headerName: "Profitable Trades",
			width: 150,
		},
		{
			field: "lossLowest",
			headerName: "Loss Lowest",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "lossHighest",
			headerName: "Loss Highest",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "lossAverage",
			headerName: "Loss Average",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "lossTotal",
			headerName: "Loss Total",
			width: 150,
			valueFormatter: moneyFormatting,
			cellClassName: getFormatClassName,
		},
		{
			field: "lossCount",
			headerName: "Lost Trades",
			width: 150,
		},
		{
			field: "priceEntryLowest",
			headerName: "Price Entry Lowest",
			width: 150,
			valueFormatter: moneyFormatting,
		},
		{
			field: "priceEntryAverage",
			headerName: "Price Entry Average",
			width: 150,
			valueFormatter: moneyFormatting,
		},
		{
			field: "priceExitLowest",
			headerName: "Price Exit Lowest",
			width: 150,
			valueFormatter: moneyFormatting,
		},
		{
			field: "priceExitHighest",
			headerName: "Price Exit Highest",
			width: 150,
			valueFormatter: moneyFormatting,
		},
		{
			field: "priceExitAverage",
			headerName: "Price Exit Average",
			width: 150,
			valueFormatter: moneyFormatting,
		},
	];
	return (
		<Box className={styles["analysis-data-grid"]}>
			{rows.length === 0 ? (
				<Typography variant="h6" align="center">
					No data available at the moment. Please initiate the analysis by selecting your desired parameters or import a pre generated report.
				</Typography>
			) : (
				<div className={styles["data-grid-holder"]}>
					<div
					 className={styles["export-holder"]}>
						<Button variant="contained" color="primary" onClick={() => exportToExcel()}>
							Export to Excel
						</Button>
					</div>

					<DataGrid
						apiRef={apiRef} // Pass the apiRef to the DataGrid
						rows={rows}
						columns={columns}
						className={styles["data-grid"]}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: pageSize,
								},
							},
							columns: {
								columnVisibilityModel,
							},
						}}
						onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)} // Add this line
						pageSizeOptions={[5, 10, 20, 25, 50, 100]}
						onRowClick={(params) => handleRowClick(params.row)}
						getRowClassName={(params) => (params.id === selectedRowId ? styles.selectedRow : "")}
					/>
				</div>
			)}
		</Box>
	);
};

export default AnanlysisDataGrid;
