import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";
import moment from "moment";
import styles from "./StrikeGraph.module.scss";
import { IStrikeData, IStrikeDataPoint } from "../../../interfaces/IGraph";
import { useTheme } from "../../../contexts/ThemeProviderContext";
import { useData } from "../../../contexts/DataProvider";
import { ThemeType } from "../../../config/config";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

interface StrikeGraphProps {
	data: IStrikeData[];
	spot_price?: number; // Optional spot price
	resetGraph?: boolean;
}

// Function to lighten a color
const lighterColor = (color: string, amount: number) => {
	const col = color.slice(1); // Remove the '#' symbol
	const num = parseInt(col, 16); // Convert hex to decimal
	const r = (num >> 16) + amount; // Red
	const g = ((num >> 8) & 0x00ff) + amount; // Green
	const b = (num & 0x0000ff) + amount; // Blue

	// Ensure RGB values do not exceed 255
	const newR = Math.min(255, r);
	const newG = Math.min(255, g);
	const newB = Math.min(255, b);

	return `#${(0x1000000 + (newR << 16) + (newG << 8) + newB).toString(16).slice(1).padStart(6, "0")}`;
};

const StrikeGraph: React.FC<StrikeGraphProps> = ({ data, spot_price, resetGraph }) => {
	const { themeMode } = useTheme();
	const { appConfig } = useData();
	const [chartData, setChartData] = useState<any>(null);
	const [sortedData, setSortedData] = useState<IStrikeDataPoint[]>([]);

	const [startingQuarterInterval, setStartingQuarterInterval] = useState<number>(0);
	const [endingQuarterInterval, setEndingQuarterInterval] = useState<number>(0);

	// Add resetGraph logic
	useEffect(() => {
		setChartData(null);
		setSortedData([]);
		setStartingQuarterInterval(0);
		setEndingQuarterInterval(0);
	}, [resetGraph]);

	useEffect(() => {
		if (!data || data.length === 0) return;

		const datasets: any[] = [];
		const timestamps: number[] = []; // Store timestamps

		const baseCallColor = "#0000FF"; // Red for call
		const basePutColor = "#FF0000"; // Blue for put
		const fadePercent = 10;

		// Pre-calculate the two relevant strike prices based on the spot_price
		const lowerStrikePrice = spot_price ? Math.floor(spot_price / 100) * 100 : 0; // e.g., 54300
		const upperStrikePrice = spot_price ? lowerStrikePrice + 100 : 0; // e.g., 54400

		// For each strike data, filter only relevant calls and puts
		data.forEach((strike, index) => {
			const strikeId = strike._id;

			// Prepare call data and filter based on the calculated strike prices
			strike.CALL[0].forEach((call: IStrikeDataPoint) => {
				if (call.strike_price === lowerStrikePrice || lowerStrikePrice === 0) {
					// Specify type here
					const callColor = lighterColor(baseCallColor, index * fadePercent);
					const existingCallDataset = datasets.find((dataset) => dataset.label === `${strikeId}_call`);
					if (existingCallDataset) {
						existingCallDataset.data.push({ x: call.ltt, y: call.ltp });
					} else {
						datasets.push({
							label: `${strikeId}_call`,
							data: [{ x: call.ltt, y: call.ltp }],
							borderColor: callColor,
							borderWidth: 1,
							fill: false,
							tension: 0.1,
							pointRadius: 0,
						});
					}
					timestamps.push(new Date(call.ltt).getTime());
				}
			});

			// Prepare put data and filter based on the calculated strike prices
			strike.PUT[0].forEach((put: IStrikeDataPoint) => {
				if (put.strike_price === upperStrikePrice || lowerStrikePrice === 0) {
					// Specify type here
					const putColor = lighterColor(basePutColor, index * fadePercent);
					const existingPutDataset = datasets.find((dataset) => dataset.label === `${strikeId}_put`);
					if (existingPutDataset) {
						existingPutDataset.data.push({ x: put.ltt, y: put.ltp });
					} else {
						datasets.push({
							label: `${strikeId}_put`,
							data: [{ x: put.ltt, y: put.ltp }],
							borderColor: putColor,
							borderWidth: 1,
							fill: false,
							tension: 0.1,
							pointRadius: 0,
						});
					}
					timestamps.push(new Date(put.ltt).getTime());
				}
			});
		});

		// Calculate the first and last timestamp for the x-axis
		if (timestamps.length > 0) {
			const firstTime = new Date(Math.min(...timestamps));
			const lastTime = new Date(Math.max(...timestamps));

			// Set the starting and ending intervals
			setStartingQuarterInterval(new Date(Math.floor(firstTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());
			setEndingQuarterInterval(new Date(Math.ceil(lastTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());

			// Set the chart data
			setChartData({ datasets });
		}
	}, [data, spot_price]);

	// Format the start and end dates
	const startDate = moment(sortedData[0]?.ltt).format("DD-MMM hh:mm");
	const endDate = moment(sortedData[sortedData.length - 1]?.ltt).format("DD-MMM hh:mm");

	return (
		<div className={styles["strike-graph"]}>
			{chartData && chartData.datasets.length > 0 && (
				<Line
					className={styles["graph"]}
					data={{
						labels: chartData.datasets.flatMap((dataset: { data: any[] }) => dataset.data.map((point: { x: any }) => point.x)),
						datasets: chartData.datasets,
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								type: "time",
								min: startingQuarterInterval, // Set the minimum to the nearest 15-minute mark
								max: endingQuarterInterval, // Set the maximum to the nearest 15-minute mark
								time: {
									unit: "minute",
									tooltipFormat: appConfig?.timeFormats?.shortHm || "HH:mm",
									displayFormats: {
										minute: appConfig?.timeFormats?.shortHm || "HH:mm",
									},
								},
								title: {
									display: true,
									text: "Time",
								},
								ticks: {
									stepSize: 15,
									source: "auto",
									autoSkip: true,
									callback: function (value) {
										const date = moment(value);
										return date.format(appConfig?.timeFormats?.shortHm || "HH:mm");
									},
								},
								grid: {
									color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
								},
							},
							y: {
								title: {
									display: true,
									text: "Strike Price (LTP)",
								},
								grid: {
									color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
								},
							},
						},
						plugins: {
							legend: {
								display: true,
								position: "bottom",
							},
							tooltip: {
								callbacks: {
									label: (tooltipItem) => {
										return `${tooltipItem.dataset.label}: ${tooltipItem.label} ₹${tooltipItem.formattedValue}`;
									},
								},
							},
						},
					}}
				/>
			)}
		</div>
	);
};

export default StrikeGraph;
