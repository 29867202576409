import React, { useContext, useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, FormControlLabel, Switch, Tabs, Tab, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../../../contexts/AuthContext";
import { useLogger } from "../../../contexts/LoggingContext";
import { useWebSocket } from "../../../contexts/WebSocketContext";
import { useTheme } from "../../../contexts/ThemeProviderContext";

import UserSettingsDialog from "../../dialogs/UserSettingsDialog";
import BrokerSettingsDialog from "../../dialogs/BrokerSettingsDialog/BrokerSettingsDialog";
import StratergySettingsDialog from "../../dialogs/StratergySettingsDialog/StratergySettingsDialog";
import LogoutDialog from "../../dialogs/LogoutDialog";
import FundsManagerDialog from "../../dialogs/FundsManagerDialog/FundsManagerDialog";
import WindowOrderDialog from "../../dialogs/WindowOrderDialog";
import AboutDialog from "../../dialogs/AboutDialog";

import { ThemeType } from "../../../config/config";

import ConnectivityIndicator from "../ConnectivityIndicator";
import SubscriptionIndicator from "../SubscriptionIndicator/SubscriptionIndicator";

import styles from "./TopBar.module.scss";
import OptionChainIndicator from "../OptionChainIndicator/OptionChainIndicator";

const TopBar: React.FC = () => {
	const { themeMode, toggleTheme } = useTheme();
	const { isConnected } = useWebSocket();
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openDialog, setOpenDialog] = useState<string | null>(null);
	const { showLogger, setShowLogger } = useLogger();

	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleOpenDialog = (dialogType: string) => {
		setOpenDialog(dialogType);
		handleMenuClose();
	};

	const handleCloseDialog = () => {
		setOpenDialog(null);
	};

	const handleLogout = () => {
		if (auth) {
			auth.logout();
		}
		handleCloseDialog();
	};

	const handleToggleLogger = () => {
		setShowLogger(!showLogger);
	};

	const userName = localStorage.getItem("username") || "User";
	const currentTab = location.pathname;

	// Check if the current page is the login page
	const isLoginPage = currentTab === "/login";

	return (
		<div className={styles["top-bar"]}>
			<AppBar position="static">
				<Toolbar className={styles["tool-bar"]}>
					<div className={styles["left-segment"]}>
						<Typography variant="h6" style={{ flexGrow: 1 }}>
							iNitrex
						</Typography>

						<ConnectivityIndicator />
						<SubscriptionIndicator />
						<OptionChainIndicator />
					</div>

					{auth?.isAuthenticated && (
						<Tabs
							value={["/trade", "/analysis", "/orderAndTrade"].includes(currentTab) ? currentTab : "/trade"}
							onChange={(event, newValue) => {
								const validRoutes = ["/trade", "/analysis", "/orderAndTrade", "/about"];
								if (validRoutes.includes(newValue)) {
									navigate(newValue);
								}
							}}
							aria-label="nav tabs">
							<Tab label="Trade" value="/trade" disabled={currentTab === "/trade"} />
							<Tab label="Analysis" value="/analysis" disabled={currentTab === "/analysis"} />
							<Tab label="Order & Trade" value="/orderAndTrade" disabled={currentTab === "/orderAndTrade"} />
						</Tabs>
					)}

					<div className={styles["right-segment"]}>
						{auth?.isAuthenticated ? `Welcome ${userName}` : ""}

						<IconButton edge="end" color="inherit" onClick={handleMenuClick}>
							<MenuIcon />
						</IconButton>

						<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
							{!auth?.isAuthenticated &&
								!isLoginPage && auth?.isVendorAuthenticated && [
									<MenuItem key="login" onClick={() => navigate("/login")}>
										Sign in
									</MenuItem>,
									<Divider key="d1" />,
								]}

							<MenuItem>
								<FormControlLabel control={<Switch checked={themeMode === ThemeType.dark} onChange={toggleTheme} color="primary" />} label={`Switch to ${themeMode === ThemeType.dark ? ThemeType.light : ThemeType.dark} Theme`} />
							</MenuItem>

							{auth?.isAuthenticated && [
								<MenuItem key="logger">
									<FormControlLabel control={<Switch checked={showLogger} onChange={handleToggleLogger} color="primary" />} label={`Logger ${showLogger ? "On" : "Off"}`} />
								</MenuItem>,
								<Divider key="d2" />,
								isConnected && (
									<MenuItem key="funds" onClick={() => handleOpenDialog("funds")}>
										Funds Manager
									</MenuItem>
								),
								isConnected && (
									<MenuItem key="windowOrderMananger" onClick={() => handleOpenDialog("windowOrderMananger")}>
										Window Order Manager
									</MenuItem>
								),

								<Divider key="d3" />,
								<MenuItem key="trade" onClick={() => handleOpenDialog("trade")}>
									Trade & Stratergy Settings
								</MenuItem>,
								<MenuItem key="broker" onClick={() => handleOpenDialog("broker")}>
									Broker App Settings
								</MenuItem>,
								<MenuItem key="user" onClick={() => handleOpenDialog("user")}>
									User Settings
								</MenuItem>,

								<Divider key="d4" />,
								<MenuItem key="about" onClick={() => handleOpenDialog("about")}>
									About
								</MenuItem>,
								<MenuItem key="logout" onClick={() => handleOpenDialog("logout")}>
									Logout
								</MenuItem>,
							]}
						</Menu>
					</div>
				</Toolbar>
			</AppBar>

			{/* Dialogs */}
			<UserSettingsDialog open={openDialog === "user"} onClose={handleCloseDialog} />
			<FundsManagerDialog open={openDialog === "funds"} onClose={handleCloseDialog} />
			<WindowOrderDialog open={openDialog === "windowOrderMananger"} onClose={handleCloseDialog} />
			<BrokerSettingsDialog open={openDialog === "broker"} onClose={handleCloseDialog} />
			<StratergySettingsDialog open={openDialog === "trade"} onClose={handleCloseDialog} />
			<AboutDialog open={openDialog === "about"} onClose={handleCloseDialog} />
			<LogoutDialog open={openDialog === "logout"} onClose={handleCloseDialog} onLogout={handleLogout} />
		</div>
	);
};

export default TopBar;
