import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

interface ProtectedRouteProps {
	element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
	const auth = useContext(AuthContext);
	const location = useLocation();

	if (auth === undefined || !auth.isAuthenticated) {
		// Redirect them to the login page if not authenticated
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return element;
};

export default ProtectedRoute;
