import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

const container = document.getElementById("root");

if (container) {
	// Check if container is not null
	const root = createRoot(container);

	root.render(
		<React.StrictMode>
			<Router>
				<App />
			</Router>
		</React.StrictMode>,
	);
} else {
	console.error("Root container not found.");
}
