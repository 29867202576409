import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";
import moment from "moment";

import { ThemeType } from "../../../config/config";

import { IGraphData, IStrategies } from "../../../interfaces/IGraph";
import { DataUtils } from "../../../utils/DataUtils";

import { useData } from "../../../contexts/DataProvider";
import { useTheme } from "../../../contexts/ThemeProviderContext";

import TradeGraph from "../TradeGraph/TradeGraph";
import styles from "./PLGraph.module.scss";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const colors = [
	"#FF6F61",
	"#6B5B95",
	"#88B04B",
	"#F7D6C1",
	"#FFABAB",
	"#FFC3A0",
	"#DCE2E5",
	"#FF677D",
	"#D4A5A5",
	"#F4A261",
	"#2A9D8F",
	"#E76F51",
	"#B9EBCF",
	"#F9DBBD",
	"#F2B5D4",
	"#A2D9CE",
	"#FF6F91",
	"#B0A9A1",
	"#7D5A50",
	"#4A90E2",
	"#B3B0A4",
	"#C4E0E5",
	"#F5C6C6",
	"#9B9B9B",
	"#2E8B57",
	"#C9BBCF",
	"#F0E5CF",
	"#B8E2F2",
	"#B2F5D4",
	"#D1B6E2",
	"#F3B4B4",
	"#D4E157",
	"#D1F2A5",
	"#FFBF00",
	"#F6E3B4",
	"#7FDBFF",
	"#1F8A70",
	"#D0E1D4",
	"#F2D1C9",
	"#C6E2FF",
	"#FFC107",
	"#FF6F00",
	"#7B68EE",
	"#E6E6FA",
	"#8A2BE2",
	"#FF4500",
	"#32CD32",
	"#DC143C",
	"#1E90FF",
	"#D8BFD8",
];

type FilterCategory = "continuity" | "priceHikePercentage" | "tradeStopLoss";

interface PLGraphProps {
	data: IStrategies[];
	resetGraph?: boolean;
}

const PLGraph: React.FC<PLGraphProps> = ({ data, resetGraph }) => {
	const { themeMode } = useTheme();
	const { appConfig } = useData();
	const [showFilters, setShowFilters] = useState<boolean>(true);
	const [graphData, setGraphData] = useState<IGraphData>({});
	const [chartData, setChartData] = useState<any>(null);
	const [tradeGraphData, setTradeGraphData] = useState<any>(null);

	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	const [startingQuarterInterval, setStartingQuarterInterval] = useState<number>(0);
	const [endingQuarterInterval, setEndingQuarterInterval] = useState<number>(0); // New state for end interval

	const [selectedFilters, setSelectedFilters] = useState<Record<FilterCategory, Set<string | number>>>({
		continuity: new Set(),
		priceHikePercentage: new Set(),
		tradeStopLoss: new Set(),
	});

	const [filterOptions, setFilterOptions] = useState<{
		continuity: string[];
		priceHikePercentage: number[];
		tradeStopLoss: number[];
	}>({
		continuity: [],
		priceHikePercentage: [],
		tradeStopLoss: [],
	});

	useEffect(() => {
		setGraphData({});
		setChartData(null);
		setTradeGraphData(null);
		setShowFilters(true);
		setStartDate("");
		setEndDate("");
		setStartingQuarterInterval(0);
		setEndingQuarterInterval(0);
		setSelectedFilters({
			continuity: new Set(),
			priceHikePercentage: new Set(),
			tradeStopLoss: new Set(),
		});
	}, [resetGraph]);

	useEffect(() => {
		const continuitySet = new Set<string>();
		const priceHikePercentageSet = new Set<number>();
		const stopLossSet = new Set<number>();

		data.forEach((strategy) => {
			continuitySet.add(strategy.plStatement.strategy.continuity);
			priceHikePercentageSet.add(strategy.plStatement.strategy.priceHikePercentage);
			stopLossSet.add(strategy.plStatement.strategy.tradeStopLoss);
		});

		const allContinuity = Array.from(continuitySet);
		const allPriceHikePercentage = Array.from(priceHikePercentageSet);
		const allStopLoss = Array.from(stopLossSet);

		setFilterOptions({
			continuity: allContinuity,
			priceHikePercentage: allPriceHikePercentage,
			tradeStopLoss: allStopLoss,
		});

		setSelectedFilters({
			continuity: new Set(allContinuity),
			priceHikePercentage: new Set(allPriceHikePercentage),
			tradeStopLoss: new Set(allStopLoss),
		});
	}, [data]);

	useEffect(() => {
		const transformedData: IGraphData = {};

		data.forEach((strategy) => {
			const { continuity, priceHikePercentage, tradeStopLoss } = strategy.plStatement.strategy;
			const key = `${continuity}_${priceHikePercentage}_${tradeStopLoss}`;

			if (selectedFilters.continuity.has(continuity) && selectedFilters.priceHikePercentage.has(priceHikePercentage) && selectedFilters.tradeStopLoss.has(tradeStopLoss)) {
				const combinedGraphPoints = [...strategy.results.gain, ...strategy.results.loss];
				combinedGraphPoints.sort((a, b) => new Date(a.entry.ltt).getTime() - new Date(b.entry.ltt).getTime());
				transformedData[key] = {
					points: combinedGraphPoints,
					trail: combinedGraphPoints.map((point) => point.trail),
					entryExit: combinedGraphPoints.map((point) => [point.entry.ltp, point.exit.ltp]),
				};
			}
		});

		setGraphData(transformedData);
	}, [data, selectedFilters]);

	useEffect(() => {
		if (Object.keys(graphData).length === 0) return;

		let earliestTime: number | null = null;
		let latestTime: number | null = null;

		// Sort data by entry time and find the earliest and latest times across all datasets
		Object.keys(graphData).forEach((key) => {
			let { points } = graphData[key];

			// Sort points by entry time (ascending order)
			points = points.sort((a, b) => new Date(a.entry.ltt).getTime() - new Date(b.entry.ltt).getTime());

			const pointTimesEntry = points.map((point) => new Date(point.entry.ltt).getTime()); // Convert to number (timestamp)
			const pointTimesExit = points.map((point) => new Date(point.exit.ltt).getTime()); // Convert to number (timestamp)
			const minTime = Math.min(...pointTimesEntry); // Get the earliest time from the current dataset
			const maxTime = Math.max(...pointTimesExit); // Get the latest time from the current dataset

			// Update earliestTime if it's null or minTime is smaller
			if (earliestTime === null || minTime < earliestTime) {
				earliestTime = minTime;
			}
			// Update latestTime if it's null or maxTime is larger
			if (latestTime === null || maxTime > latestTime) {
				latestTime = maxTime;
			}

			// Optionally, you can replace the original unsorted points back into graphData if needed.
			graphData[key].points = points;
		});

		if (earliestTime !== null) setStartDate(moment(earliestTime).format("DD-MMM HH:mm"));
		if (latestTime !== null) setEndDate(moment(latestTime).format("DD-MMM HH:mm"));

		// Ensure earliestTime is not null before proceeding
		if (earliestTime !== null) {
			const startTimeMoment = moment(earliestTime).startOf("minute");

			// Calculate the nearest multiple of 15 minutes
			const remainder = startTimeMoment.minute() % 15;
			if (remainder !== 0) {
				startTimeMoment.subtract(remainder, "minutes");
			}

			setStartingQuarterInterval(startTimeMoment.valueOf());
		}

		// Ensure latestTime is not null before proceeding
		if (latestTime !== null) {
			const endTimeMoment = moment(latestTime);
			const remainder = endTimeMoment.minute() % 15;
			if (remainder !== 0) {
				endTimeMoment.add(15 - remainder, "minutes").startOf("minute");
			}
			setEndingQuarterInterval(endTimeMoment.valueOf()); // Store as timestamp (number)
		}

		// Set the datasets for the chart
		const datasets = Object.keys(graphData).map((key, index) => {
			const { points } = graphData[key];

			const dataPoints = points.flatMap((point) => [
				{
					x: point.entry.ltt,
					y: ((point.exit.ltp - point.entry.ltp) / point.entry.ltp) * 100,
					radius: 5,
					backgroundColor: colors[index % colors.length],
				},
				{
					x: point.exit.ltt,
					y: ((point.exit.ltp - point.entry.ltp) / point.entry.ltp) * 100,
					radius: 5,
					backgroundColor: colors[index % colors.length],
				},
			]);

			return {
				label: key,
				data: dataPoints,
				borderColor: colors[index % colors.length],
				fill: false,
				pointBorderColor: colors[index % colors.length],
				pointBackgroundColor: "transparent",
				pointRadius: 5,
				showLine: true,
			};
		});

		setChartData({
			labels: graphData[Object.keys(graphData)[0]].points.map((point) => point.entry.ltt),
			datasets,
		});

		// Automatically set `tradeGraphData` if there's only one dataset
		if (Object.keys(graphData).length === 1) {
			const singleKey = Object.keys(graphData)[0];
			setTradeGraphData(graphData[singleKey].points);
		}
	}, [graphData]);

	const handleCheckboxChange = (category: FilterCategory, value: string | number) => {
		setSelectedFilters((prev) => {
			const newSet = new Set(prev[category]);
			if (newSet.has(value)) {
				newSet.delete(value);
			} else {
				newSet.add(value);
			}
			return { ...prev, [category]: newSet };
		});
	};

	const renderCheckboxes = (category: FilterCategory) => {
		return filterOptions[category].map((option: string | number) => (
			<div key={option.toString()}>
				<input type="checkbox" id={`${category}-${option}`} checked={selectedFilters[category].has(option)} onChange={() => handleCheckboxChange(category, option)} />
				<label htmlFor={`${category}-${option}`}>{option}</label>
			</div>
		));
	};

	return (
		<div className={styles["pl-graph"]}>
			{chartData && (
				<div className={styles["container"]}>
					<div className={styles["chart-header"]}>
						{`Trade performance from ${startDate} to ${endDate}`}
						<FormControlLabel
							control={
								<Switch
									checked={showFilters}
									onChange={() => {
										setShowFilters(!showFilters);
									}}
									color="primary"
								/>
							}
							label={`${showFilters ? "Show" : "Hide"} filters`}
						/>
					</div>
					{showFilters && (
						<div className={styles["chart-filters"]}>
							<div className={styles["strong"]}>Graph Filters</div>
							<div>
								<div>
									<div className={styles["strong"]}>Continuity</div>
									{renderCheckboxes("continuity")}
								</div>
								<div>
									<div className={styles["strong"]}>Price Hike Percentage</div>
									{renderCheckboxes("priceHikePercentage")}
								</div>
								<div>
									<div className={styles["strong"]}>Stop Loss</div>
									{renderCheckboxes("tradeStopLoss")}
								</div>
							</div>
						</div>
					)}
					<div className={styles["pl-chart"]}>
						<Line
							data={chartData}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									x: {
										type: "time",
										min: startingQuarterInterval, // Set the computed 15-minute interval as the start
										max: endingQuarterInterval, // Set the computed 15-minute interval as the end
										time: {
											unit: "minute",
											tooltipFormat: appConfig?.timeFormats?.shortHm || "HH:mm",
											displayFormats: {
												minute: appConfig?.timeFormats?.shortHm || "HH:mm",
											},
										},
										title: {
											display: true,
											text: "Time (Entry)",
										},
										ticks: {
											stepSize: 15, // Number of units (15 minutes) between grid lines
											source: "auto", // Automatically adjust based on chart size and data
											autoSkip: true, // Skips ticks to avoid overlap
											callback: function (value) {
												const date = moment(value);
												return date.format(appConfig?.timeFormats?.shortHm || "HH:mm");
											},
										},
										grid: {
											color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
										},
									},
									y: {
										title: {
											display: true,
											text: "Profit/Loss (%)",
										},
										ticks: {
											padding: 15, // Add space between the y-axis labels and grid
										},
										grid: {
											color: (ctx) => {
												// Check if the line is at 0 to set a darker color
												return ctx.tick.value === 0 ? (themeMode === ThemeType.dark ? "#FFF" : "#000") : themeMode === ThemeType.dark ? "#333" : "#e0e0e0";
											},
											lineWidth: (ctx) => (ctx.tick.value === 0 ? 2 : 1), // Thicken the 0 line
										},
									},
								},
								plugins: {
									legend: {
										display: true,
										position: "top",
									},
									tooltip: {
										callbacks: {
											title: (tooltipItems) => {
												const firstItem = tooltipItems[0];
												const strategyKey = firstItem?.dataset?.label || "";
												return `Strategy: ${strategyKey}`;
											},
											label: (tooltipItem) => {
												const pointIndex = Math.floor(tooltipItem.dataIndex / 2);
												const key = tooltipItem.dataset.label;

												if (key && graphData[key]) {
													const point = graphData[key].points[pointIndex];
													const trail = graphData[key].trail[pointIndex];
													const entryExit = graphData[key].entryExit[pointIndex];

													setTradeGraphData(graphData[key].points);

													if (trail && entryExit) {
														const maxTrailValue = Math.max(...trail);
														const minTrailValue = Math.min(...trail);
														const formattedTrail = trail.map((value) => (value === maxTrailValue ? `⇑__${value}__⇑` : value === minTrailValue ? `⇓__${value}__⇓` : value));

														const chunkSize = formattedTrail.length < 150 ? 10 : 20;
														const chunkedTrail = [];
														for (let i = 0; i < formattedTrail.length; i += chunkSize) {
															const chunk = formattedTrail.slice(i, i + chunkSize).join(", ");
															chunkedTrail.push(chunk);
														}

														return [
															`${point.strike.price} || ${point.strike.right} || ₹ ${DataUtils.calcPL(point, appConfig)} || ${DataUtils.calcPercentage(entryExit[0], entryExit[1])}%`,
															`Max ₹ ${maxTrailValue} (${DataUtils.calcPercentage(entryExit[0], maxTrailValue)}%) || Min ₹ ${minTrailValue} (${DataUtils.calcPercentage(entryExit[0], minTrailValue)}%)`,
															`Buy: ${moment(point.entry.ltt_r).format("HH:mm:ss")} || ₹ ${entryExit[0]}`,
															`Sell: ${moment(point.exit.ltt_r).format("HH:mm:ss")} || ₹ ${entryExit[1]}`,
															`Trail:`,
															...chunkedTrail,
														];
													}
													return ["Trail: N/A"];
												}
												return ["Trail: N/A"];
											},
										},
									},
								},
							}}
						/>
					</div>
					<TradeGraph trades={tradeGraphData}></TradeGraph>
				</div>
			)}
		</div>
	);
};

export default PLGraph;
