/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const WS_URL = process.env.REACT_APP_WS_URL;

const config = {
	apis: {
		ws: WS_URL,
		user: {
			login: `${API_BASE_URL}user/login`,
		},
		broker: {
			details: `${API_BASE_URL}broker-details`,
		},
		analyze: `${API_BASE_URL}analyze`,
	},
	vendor: {
		icici: "https://api.icicidirect.com/apiuser/home",
	},
};

export enum MessageType {
	message = "message",
	error = "error",

	tradeStarted = "trade-started",
	tradeEnded = "trade-ended",
	tradeModeChanged = "trade-mode-changed",

	positionOpened = "position-opened",
	positionClosed = "position-closed",

	liveStock = "live-stock",
	liveStrike = "live-strike",

	subscribedStock = "subscribed-stock",
	unsubscribedStock = "unsubscribed-stock",

	initialConfig = "initial-config",
	funds = "funds",

	tradeList = "trade-list",
	orderList = "order-list",

	orderPlacement = "order-placement",
	orderSquareOff = "order-square-off",

	userConfig = "user-config",
	configResetted = "config-resetted",
	configUpdated = "config-updated",
}

export enum clientRequestType {
	initialHandShake = "initial-hand-shake",
	initialPayload = "initial-payload",

	tradeStart = "trade-start",
	tradeEnd = "trade-end",
	tradeModeChange = "trade-mode-change",

	subscribeStock = "subscribe-stock",
	unsubscribeStock = "unsubscribe-stock",

	fundsSet = "funds-set",
	fundsGet = "funds-get",

	tradeList = "trade-list",
	orderList = "order-list",

	placeOrder = "place-order",
	squareOffOrder = "square-off-order",

	configReset = "config-reset",
	configUpdate = "config-update",

	mayDay = "may-day",
}

export enum ThemeType {
	light = "Light",
	dark = "Dark",
}

export enum TradeType {
	paper = "paper",
	cash = "cash",
}

export default config;
