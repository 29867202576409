import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Slider, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import { clientRequestType, MessageType } from "../../../config/config";
import { IInterval, IStopLoss, IStrategies, IUserConfig } from "../../../interfaces/IUserConfig";
import { useWebSocket } from "../../../contexts/WebSocketContext";
import { useData } from "../../../contexts/DataProvider";
import styles from "./StratergySettingsDialog.module.scss";
import moment from "moment";

interface StratergySettingsDialogProps {
	open: boolean;
	onClose: () => void;
}

const StratergySettingsDialog: React.FC<StratergySettingsDialogProps> = ({ open, onClose }) => {
	const { appConfig, userConfig } = useData();
	const { sendMessage, receiveMessage } = useWebSocket();

	const [initialData, setInitialData] = useState<IUserConfig | null>(null);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formDisabled, setFormDisabled] = useState(false);

	const defaultStratergies = {
		downFallEntry: false,
		lazy: false,
		projectile: false,
		steepDown: false,
	};

	const [interval, setInterval] = useState<IInterval>({ entry: 60, exit: 60 });
	const [continuity, setContinuity] = useState<number>(5);
	const [priceHikePercentage, setPriceHikePercentage] = useState<number>(0.6);
	const [stopLoss, setStopLoss] = useState<IStopLoss>({ trade: 15, day: 20 });
	const [strategies, setStrategies] = useState<IStrategies>({
		...defaultStratergies,
	});

	const [daily, setDaily] = useState({
		entry: {
			start: {
				hours: 9,
				minutes: 15,
			},
			end: {
				hours: 15,
				minutes: 0,
			},
		},
		exit: {
			hours: 15,
			minutes: 10,
		},
	});

	const [expiry, setExpiry] = useState({
		entry: {
			start: {
				hours: 9,
				minutes: 15,
			},
			end: {
				hours: 12,
				minutes: 0,
			},
		},
		exit: {
			hours: 12,
			minutes: 10,
		},
	});
	// 9:15 AM - 3:00 PM
	const [dailyEntryRange, setDailyEntryRange] = useState<number[]>([daily.entry.start.hours * 60 + daily.entry.start.minutes, daily.entry.end.hours * 60 + daily.entry.end.minutes]);
	// 3:10 PM
	const [dailyExit, setDailyExit] = useState<number>(daily.exit.hours * 60 + daily.exit.minutes);

	// 9:15 AM - 12:00 PM
	const [expiryEntryRange, setExpiryEntryRange] = useState<number[]>([expiry.entry.start.hours * 60 + expiry.entry.start.minutes, daily.entry.end.hours * 60 + expiry.entry.end.minutes]);
	const [expiryExit, setExpiryExit] = useState<number>(daily.exit.hours * 60 + daily.exit.minutes); // 3:10 PM

	const [data, setData] = useState<IUserConfig | null>(null); // Define the type for data or use undefined

	useEffect(() => {
		if (userConfig) {
			setInitialData(userConfig); // Store the original data
			setData(userConfig); // Also set it as the current data
		}
	}, [userConfig, open]);

	const updateSettingsFromConfig = (config: IUserConfig) => {
		const qualityParams = config.qualityParams;
		const session = config.session;
		setInterval(qualityParams.interval);
		setContinuity(qualityParams.continuity);
		setPriceHikePercentage(qualityParams.priceHikePercentage);
		setStopLoss(qualityParams.stopLoss);

		setDaily(session.daily);
		setExpiry(session.expiry);

		setDailyEntryRange([session.daily.entry.start.hours * 60 + session.daily.entry.start.minutes, session.daily.entry.end.hours * 60 + session.daily.entry.end.minutes]);
		setExpiryEntryRange([session.expiry.entry.start.hours * 60 + session.expiry.entry.start.minutes, session.expiry.entry.end.hours * 60 + session.expiry.entry.end.minutes]);

		setDailyExit(session.daily.exit.hours * 60 + session.daily.exit.minutes);
		setExpiryExit(session.expiry.exit.hours * 60 + session.expiry.exit.minutes);

		try {
			const strategyNames: string[] = qualityParams.strategies;
			const updatedStrategies: IStrategies = {
				...defaultStratergies,
			};

			strategyNames.forEach((strategy) => {
				updatedStrategies[strategy] = true;
			});

			setStrategies(updatedStrategies);
		} catch (error) {}
	};

	useEffect(() => {
		if (data && data.qualityParams) {
			updateSettingsFromConfig(data);
		}
	}, [data]);

	useEffect(() => {
		receiveMessage((message) => {
			switch (message.type) {
				case MessageType.configResetted:
					setIsSubmitting(false);
					setData(message.data);
					break;
				case MessageType.configUpdated:
					setIsSubmitting(false);
					break;
			}
		});
	}, [receiveMessage]);

	const handleCheckboxChange = (event: { target: { name: string; checked: boolean } }) => {
		setStrategies({
			...strategies,
			[event.target.name]: event.target.checked,
		});
	};

	const revertToPlatformSettings = () => {
		const userId = localStorage.getItem("user_id");
		const payload = {
			userId,
		};

		setIsSubmitting(true);
		sendMessage({
			type: clientRequestType.configReset,
			data: payload,
		});
	};
	const updateSettings = async () => {
		// Filter selected strategies
		const userId = localStorage.getItem("user_id");
		const selectedStrategies = Object.keys(strategies)
			.filter((key) => strategies[key as keyof IStrategies]) // Type assertion here
			.map((key) => key);

		const payload = {
			userId,
			userConfig: {
				qualityParams: {
					continuity,
					priceHikePercentage,
					interval,
					stopLoss,
					strategies: selectedStrategies,
				},
				session: {
					daily: {
						entry: {
							start: {
								hours: Math.floor(dailyEntryRange[0] / 60),
								minutes: dailyEntryRange[0] % 60,
							},
							end: {
								hours: Math.floor(dailyEntryRange[1] / 60),
								minutes: dailyEntryRange[1] % 60,
							},
						},
						exit: {
							hours: Math.floor(dailyExit / 60),
							minutes: dailyExit % 60,
						},
					},
					expiry: {
						entry: {
							start: {
								hours: Math.floor(expiryEntryRange[0] / 60),
								minutes: expiryEntryRange[0] % 60,
							},
							end: {
								hours: Math.floor(expiryEntryRange[1] / 60),
								minutes: expiryEntryRange[1] % 60,
							},
						},
						exit: {
							hours: Math.floor(expiryExit / 60),
							minutes: expiryExit % 60,
						},
					},
				},
			},
		};

		setIsSubmitting(true);
		sendMessage({
			type: clientRequestType.configUpdate,
			data: payload,
		});
	};

	const handleClose = () => {
		if (initialData) {
			setData(initialData); // Reset to original data if user closes the dialog
			updateSettingsFromConfig(initialData);
		}
		onClose(); // Call the parent's onClose function to close the dialog
	};

	const dateSliderTimeFormat = (value: number) => {
		return moment().startOf("day").minutes(value).format("HH:mm");
	};

	if (!appConfig || !appConfig.session) {
		// Handle the case where appConfig or daily is undefined
		return <div>Loading...</div>; // Or handle it however you'd like (e.g., a loader)
	}

	return (
		<Dialog open={open} onClose={handleClose} className={styles["stratergy-settings"]}>
			<DialogTitle>Trade & Stratergy Settings</DialogTitle>
			<DialogContent>
				<Typography>
					To enhance your trading strategy, adjust the settings for key parameters. Set your risk tolerance with the continuity value, determine your price hike percentage for entry points, and configure your stop-loss settings for both trade and day.
					<br />
					<br />
					Regularly update these values to adapt to market conditions and optimize your performance!
					<br />
					<br />
					<span className={styles.note}>Note: A higher stop-loss percentage provides more room for fluctuations, boosting recovery chances during volatility. However, it also increases the risk of larger losses. Assess your risk tolerance and market conditions before adjusting.</span>
				</Typography>

				<div className={styles["grid-container"]}>
					{/* Row 1: Strategies */}
					<div className={styles["grid-item"]}>Strategies</div>
					<div className={`${styles["grid-item"]} ${styles["span-row-1"]} ${styles["stratergies"]}`}>
						<Tooltip title="Entered by mistake? When the stock slips at the second trailing point, it loses 50% of the time. Why risk further losses?">
							<FormControlLabel control={<Checkbox size="small" name="downFallEntry" checked={strategies.downFallEntry} onChange={handleCheckboxChange} />} label="Down fall entry" disabled={formDisabled} />
						</Tooltip>

						<Tooltip title="Reached a certain height but neither increasing nor decreasing. Why wait for it to hit the stop-loss?">
							<FormControlLabel control={<Checkbox size="small" name="projectile" checked={strategies.projectile} onChange={handleCheckboxChange} />} label="Projectile" disabled={formDisabled} />
						</Tooltip>

						<Tooltip title="Even after 10 hits, price is below than entry by 1%">
							<FormControlLabel control={<Checkbox size="small" name="lazy" checked={strategies.lazy} onChange={handleCheckboxChange} />} label="Lazy" disabled={formDisabled} />
						</Tooltip>

						<Tooltip title="Reached a certain height but neither increasing nor decreasing. Why wait for it to hit the stop-loss?">
							<FormControlLabel control={<Checkbox size="small" name="steepDown" checked={strategies.steepDown} onChange={handleCheckboxChange} />} label="Steep Down" disabled={formDisabled} />
						</Tooltip>
					</div>

					{/* Row 2: Continuity */}
					<div className={styles["grid-item"]}>Continuity ({continuity})</div>
					<div className={styles["grid-item"]}>
						<Slider marks size="small" value={continuity} min={2} max={10} step={1} onChange={(event, newValue) => setContinuity(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled} />
					</div>

					{/* Row 3 Price Hike Percentage*/}
					<div className={styles["grid-item"]}>Price Hike ({priceHikePercentage?.toFixed(2)}%)</div>
					<div className={styles["grid-item"]}>
						<Slider
							marks
							size="small"
							value={priceHikePercentage}
							min={0.05}
							max={2}
							step={0.05}
							onChange={(event, newValue) => setPriceHikePercentage(newValue as number)}
							valueLabelDisplay="auto"
							valueLabelFormat={(value) => value?.toFixed(2)}
							aria-labelledby="price-hike-slider"
							getAriaValueText={(value) => `${value?.toFixed(2)}`}
							disabled={formDisabled}
						/>
					</div>

					{/* Row 4: Time Interval entry */}
					<div className={`${styles["grid-item"]} ${styles["span-row"]}`}>Interval</div>

					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Entry ({interval.entry}s)</div>
					<div className={styles["grid-item"]}>
						<Slider
							marks
							size="small"
							value={interval.entry}
							min={10}
							max={120}
							step={10}
							onChange={(event, newValue) => setInterval((prev) => ({ ...prev, entry: newValue as number }))} // Only update the entry field
							valueLabelDisplay="auto"
							disabled={formDisabled}
						/>
					</div>

					{/* Row 5: Time Interval exit */}
					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Exit ({interval.exit}s)</div>
					<div className={styles["grid-item"]}>
						<Slider
							marks
							size="small"
							value={interval.exit}
							min={10}
							max={120}
							step={10}
							onChange={(event, newValue) => setInterval((prev) => ({ ...prev, exit: newValue as number }))} // Only update the exit field
							valueLabelDisplay="auto"
							disabled={formDisabled}
						/>
					</div>

					{/* Row 6 */}
					<div className={`${styles["grid-item"]} ${styles["span-row"]}`}>Stop Loss</div>

					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Trade ({stopLoss.trade?.toFixed(2)}%)</div>
					<div className={styles["grid-item"]}>
						<Slider marks size="small" value={stopLoss.trade} min={1} max={20} step={0.25} onChange={(event, newValue) => setStopLoss((prev) => ({ ...prev, trade: newValue as number }))} valueLabelDisplay="auto" disabled={formDisabled} />
					</div>

					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Day ({stopLoss.day?.toFixed(1)}%)</div>
					<div className={styles["grid-item"]}>
						<Slider marks size="small" value={stopLoss.day} min={1} max={30} step={0.5} onChange={(event, newValue) => setStopLoss((prev) => ({ ...prev, day: newValue as number }))} valueLabelDisplay="auto" disabled={formDisabled} />
					</div>

					{/* Row 7: Daily Settings */}
					<div className={`${styles["grid-item"]} ${styles["span-row"]}`}>Daily</div>
					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>
						Entry ({dateSliderTimeFormat(dailyEntryRange[0])}-{dateSliderTimeFormat(dailyEntryRange[1])})
					</div>
					<div className={`${styles["grid-item"]} ${styles["daily-entry-slider"]}`}>
						<Slider
							marks
							size="small"
							value={dailyEntryRange}
							min={appConfig.session.daily.entry.start.hours * 60 + appConfig.session.daily.entry.start.minutes}
							max={appConfig.session.daily.entry.end.hours * 60 + appConfig.session.daily.entry.end.minutes}
							step={15}
							valueLabelDisplay="auto"
							onChange={(event, newValue) => {
								const [start, end] = newValue as number[];
								if (end <= start) return;
								setDailyEntryRange(newValue as number[]);
								if (end > expiryExit) {
									setDailyExit(end);
								}
							}}
							valueLabelFormat={dateSliderTimeFormat}
							disabled={formDisabled}
						/>
					</div>
					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Exit ({dateSliderTimeFormat(dailyExit)})</div>
					<div className={styles["grid-item"]}>
						<Slider
							marks
							value={dailyExit}
							size="small"
							min={appConfig.session.daily.entry.start.hours * 60 + appConfig.session.daily.entry.start.minutes}
							max={appConfig.session.daily.entry.end.hours * 60 + appConfig.session.daily.entry.end.minutes + 15}
							step={5}
							valueLabelDisplay="auto"
							onChange={(event, newValue) => {
								const time = newValue as number;
								if (time < dailyEntryRange[1]) return;
								setDailyExit(newValue as number);
							}}
							valueLabelFormat={dateSliderTimeFormat}
							disabled={formDisabled}
						/>
					</div>

					{/* Row 8: Expiry Settings */}
					<div className={`${styles["grid-item"]} ${styles["span-row"]}`}>Expiry</div>
					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>
						Entry ({dateSliderTimeFormat(expiryEntryRange[0])}-{dateSliderTimeFormat(expiryEntryRange[1])})
					</div>
					<div className={`${styles["grid-item"]} ${styles["expiry-entry-slider"]}`}>
						<Slider
							marks
							size="small"
							value={expiryEntryRange}
							min={appConfig.session.expiry.entry.start.hours * 60 + appConfig.session.expiry.entry.start.minutes}
							max={appConfig.session.expiry.entry.end.hours * 60 + appConfig.session.expiry.entry.end.minutes}
							step={15}
							valueLabelDisplay="auto"
							onChange={(event, newValue) => {
								const [start, end] = newValue as number[];
								if (end <= start) return;
								setExpiryEntryRange(newValue as number[]);
								if (end > expiryExit) {
									setExpiryExit(end);
								}
							}}
							valueLabelFormat={dateSliderTimeFormat}
							disabled={formDisabled}
						/>
					</div>
					<div className={`${styles["grid-item"]} ${styles["sub-item"]}`}>Exit ({dateSliderTimeFormat(expiryExit)})</div>
					<div className={`${styles["grid-item"]} ${styles["expiry-exit-slider"]}`}>
						<Slider
							marks
							size="small"
							value={expiryExit}
							min={appConfig.session.expiry.entry.start.hours * 60 + appConfig.session.expiry.entry.start.minutes}
							max={appConfig.session.expiry.entry.end.hours * 60 + appConfig.session.expiry.entry.end.minutes + 15}
							step={5}
							valueLabelDisplay="auto"
							onChange={(event, newValue) => {
								const time = newValue as number;
								if (time < expiryEntryRange[1]) return;
								setExpiryExit(newValue as number);
							}}
							valueLabelFormat={dateSliderTimeFormat}
							disabled={formDisabled}
						/>
					</div>

					<div className={`${styles["grid-item"]} ${styles["span-row"]} ${styles["center-align"]}`}>
						<Button variant="contained" color="secondary" onClick={revertToPlatformSettings} disabled={formDisabled || isSubmitting}>
							Revert to platform settings
						</Button>
						<Button variant="contained" color="primary" onClick={updateSettings} disabled={formDisabled || isSubmitting}>
							Update settings
						</Button>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default StratergySettingsDialog;
