/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from "react";
import config, { clientRequestType, MessageType } from "../config/config";
import { useLogger } from "./LoggingContext";
import { useAuth } from "./AuthContext";
import { StringUtils } from "../utils/StringUtils";

interface WebSocketContextProps {
	ws: WebSocket | null;
	isConnected: boolean;
	connect: () => void;
	disconnect: () => void;
	sendMessage: (message: any) => void;
	receiveMessage: (callback: (message: any) => void) => void;
	unsubscribeMessage: (callback: (message: any) => void) => void;
	sendInitialPayload: (socket?: WebSocket) => void;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isAuthenticated } = useAuth();
	const [ws, setWs] = useState<WebSocket | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const messageCallbacksRef = useRef<Array<(message: any) => void>>([]);
	const { addLog, setShowLogger } = useLogger();

	const wsRef = useRef<WebSocket | null>(null);

	const connect = useCallback(() => {
		if (!wsRef.current) {
			const socket = new WebSocket(`${config.apis.ws}`);

			wsRef.current = socket;
			setWs(socket);

			socket.onopen = () => {
				addLog("↧ WebSocket connection established");
				setIsConnected(true);
				initializeHandShake(socket);
				sendInitialPayload(socket);
			};

			socket.onmessage = (event) => {
				const formattedData = JSON.parse(event.data);

				try {
					const isString = typeof formattedData.data === "string";
					const bypass = isOnNoShowList(formattedData);
					if (!bypass) {
						const mask = isOnNoDetailsList(formattedData);
						const data = isString ? formattedData.data : mask ? formattedData.type : JSON.stringify(formattedData.data);
						addLog(`↧ ${data}`);
					}
				} catch (error) {
					addLog(`↧ Incoming (catch): ${formattedData.data}`);
				}

				messageCallbacksRef.current.forEach((callback) => {
					callback(formattedData);
				});
			};

			socket.onclose = () => {
				addLog("↧ WebSocket connection closed");
				setIsConnected(false);

				wsRef.current = null;
				setWs(null);
			};

			socket.onerror = (error) => {
				addLog(`↧ WebSocket error: ${error}`);
				setIsConnected(false);
			};
		}
	}, []);

	const checkForErrors = (data: { type: MessageType; message: string; data?: any }) => {
		if (StringUtils.hasError(JSON.stringify(data))) {
			if (data?.data?.Error != null) {
				addLog(`Error in ${data.type} ${data?.data?.Error}`);
				setShowLogger(true);
			}
		}
	};

	const initializeHandShake = (socket: WebSocket) => {
		socket.send(
			JSON.stringify({
				type: clientRequestType.initialHandShake,
				data: {},
			})
		);
	};

	const sendInitialPayload = (socket?: WebSocket) => {
		const userId = localStorage.getItem("user_id");
		const session_token = sessionStorage.getItem("session_token");

		if (isAuthenticated) {
			if (userId && session_token) {
				const initialPayload = {
					type: clientRequestType.initialPayload,
					data: {
						userId,
						session_token,
					},
				};

				if (socket) {
					socket.send(JSON.stringify(initialPayload));
				} else {
					sendMessage(initialPayload);
				}
			} else if (session_token == null) {
				addLog("Error: Session token is missing. Please log in using the vendor link.");
			}
		} else {
			setShowLogger(false);
		}
	};

	useEffect(() => {
		isConnected && isAuthenticated && sendInitialPayload();
	}, [isConnected, isAuthenticated]);

	const isOnNoShowList = (data: { type: MessageType }) => {
		const noShowList = [MessageType.liveStock, MessageType.liveStrike];
		return data.type ? noShowList.indexOf(data.type) > -1 : false;
	};

	const isOnNoDetailsList = (data: { type: MessageType; message: string; data?: any }) => {
		const noShowList = [MessageType.initialConfig, MessageType.funds, MessageType.userConfig, MessageType.tradeList, MessageType.orderList];
		const isOnNoDetailsList = data.type ? noShowList.indexOf(data.type) > -1 : false;
		if (isOnNoDetailsList) {
			checkForErrors(data);
		}
		return isOnNoDetailsList;
	};

	const disconnect = useCallback(() => {
		if (ws) {
			ws.close();
			setIsConnected(false);
		}
	}, [ws]);

	const sendMessage = useCallback(
		(message: any) => {
			if (ws && ws.readyState === WebSocket.OPEN) {
				ws.send(JSON.stringify(message));
			} else {
				addLog("Error: WebSocket connection is not open");
			}
		},
		[ws, addLog]
	);

	const receiveMessage = useCallback((callback: (message: any) => void) => {
		messageCallbacksRef.current.push(callback);
	}, []);

	const unsubscribeMessage = useCallback((callback: (message: any) => void) => {
		messageCallbacksRef.current = messageCallbacksRef.current.filter((cb) => cb !== callback);
	}, []);

	useEffect(() => {
		return () => {
			if (ws) {
				ws.close();
			}
		};
	}, [ws]);

	return (
		<WebSocketContext.Provider
			value={{
				ws,
				isConnected,
				connect,
				disconnect,
				sendMessage,
				receiveMessage,
				unsubscribeMessage,
				sendInitialPayload,
			}}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocket = () => {
	const context = useContext(WebSocketContext);
	if (!context) {
		throw new Error("useWebSocket must be used within a WebSocketProvider");
	}
	return context;
};
