import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from "@mui/material";

interface LogoutDialogProps {
	open: boolean;
	onClose: () => void;
	onLogout: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({
	open,
	onClose,
	onLogout,
}) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Logout</DialogTitle>
			<DialogContent>
				<Typography>Are you sure you want to logout?</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onLogout} color="primary">
					Logout
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LogoutDialog;
