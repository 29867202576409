import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Container, Typography, Box } from "@mui/material";

import { AuthContext } from "../contexts/AuthContext";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useLogger } from "../contexts/LoggingContext";

const Login: React.FC = () => {
	const auth = useContext(AuthContext);
	const { setShowLogger } = useLogger();
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const navigate = useNavigate();
	const { sendInitialPayload } = useWebSocket();

	useEffect(() => {
		if (auth?.isAuthenticated) {
			moveToHome();
		} else {
			setShowLogger(false);
		}
	}, [auth?.isAuthenticated, navigate]);

	const handleLogin = async () => {
		if (auth) {
			await auth.login(email, password);
			if (auth.isAuthenticated) {
				moveToHome();
			}
		}
	};

	const moveToHome = () => {
		sendInitialPayload();
		navigate("/");
	};
	return (
		<Container maxWidth="sm">
			<Box mt={4} textAlign="center">
				<Typography variant="h4" gutterBottom>
					Login
				</Typography>
				<TextField
					fullWidth
					label="Email"
					variant="outlined"
					margin="normal"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					size="small" // Smaller text field size
				/>
				<TextField
					fullWidth
					label="Password"
					variant="outlined"
					margin="normal"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					size="small" // Smaller text field size
				/>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={handleLogin}
					sx={{ mt: 2 }}
					size="small" // Smaller button size
				>
					Log In
				</Button>
			</Box>
		</Container>
	);
};

export default Login;
