import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Checkbox, Tooltip, Radio, DialogContentText } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import config from "../../../config/config";
import { styled } from "@mui/material/styles";
import { StringUtils } from "../../../utils/StringUtils";

interface Broker {
	apiKey?: string;
	secretKey?: string;
	bd_id: number; // Using bd_id as the unique identifier
	user_id: number;
	enabled?: boolean;
	isDefault?: boolean; // Track if the broker is default
}

interface BrokerSettingsDialogProps {
	open: boolean;
	onClose: () => void;
}

const CenteredCell = styled("div")({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
});

const BrokerSettingsDialog: React.FC<BrokerSettingsDialogProps> = ({ open, onClose }) => {
	const [brokers, setBrokers] = useState<Broker[]>([]);
	const [selectedBroker, setSelectedBroker] = useState<Broker | null>(null);
	const [newApiKey, setNewApiKey] = useState<string>("");
	const [newSecretKey, setNewSecretKey] = useState<string>("");
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [deleteBdId, setDeleteBdId] = useState<number | null>(null);
	const [defaultBdId, setDefaultBdId] = useState<number | null>(null);
	const [isButtonEnabled, setIsButtonEnabled] = useState(false);

	useEffect(() => {
		const apiKeyValid = selectedBroker?.apiKey?.trim() || newApiKey.trim();
		const secretKeyValid = selectedBroker?.secretKey?.trim() || newSecretKey.trim();

		// Enable the button only if both fields have text
		setIsButtonEnabled(!!apiKeyValid && !!secretKeyValid);
	}, [selectedBroker, newApiKey, newSecretKey]);

	const userId = localStorage.getItem("user_id");

	useEffect(() => {
		if (open && userId) {
			axios
				.get(`${config.apis.broker.details}?user_id=${userId}`)
				.then((response) => {
					setBrokers(
						response.data.map((broker: any) => ({
							...broker,
							id: broker.bd_id, // Ensure each row has an `id` property
							isDefault: broker.isDefault || false, // Track if it's default
						}))
					);
					// Set defaultBdId to the currently default broker's bd_id
					const defaultBroker = response.data.find((broker: any) => broker.isDefault);

					const lsBdId = Number(localStorage.getItem("bd_id"));
					if (defaultBroker) {
						setDefaultBdId(defaultBroker.bd_id);
					} else if (lsBdId) {
						setDefaultBdId(lsBdId);
					}
				})
				.catch((error) => {
					console.error("Failed to fetch broker app details", error);
				});
		}
	}, [open, userId]);

	const handleUpdate = (bd_id: number) => {
		axios
			.put(`${config.apis.broker.details}/${bd_id}`, {
				apiKey: selectedBroker?.apiKey,
				secretKey: selectedBroker?.secretKey,
				isDefault: bd_id === defaultBdId, // Mark as default if selected
			})
			.then(() => {
				const updatedBrokers = brokers.map((broker) =>
					broker.bd_id === bd_id
						? {
								...broker,
								apiKey: selectedBroker?.apiKey,
								secretKey: selectedBroker?.secretKey,
								isDefault: bd_id === defaultBdId, // Mark as default if selected
						  }
						: broker
				);
				setBrokers(updatedBrokers);
				setSelectedBroker(null);
			})
			.catch((error) => {
				console.error("Failed to update broker app details", error);
			});
	};

	const handleDelete = (bd_id: number) => {
		axios
			.delete(`${config.apis.broker.details}?bd_id=${bd_id}`)
			.then(() => {
				setBrokers(brokers.filter((broker) => broker.bd_id !== bd_id));
				setDeleteBdId(null);
			})
			.catch((error) => {
				console.error("Failed to delete broker app details", error);
			});
	};

	const handleBulkDelete = () => {
		setConfirmDeleteOpen(true);
	};

	const confirmBulkDelete = () => {
		setConfirmDeleteOpen(false);
		const idsToDelete = selectedIds.length === 0 ? [Number(deleteBdId)] : selectedIds;
		idsToDelete.forEach((id) => handleDelete(id));
		setSelectedIds([]);
	};

	const handleSave = () => {
		if (userId) {
			axios
				.post(config.apis.broker.details, {
					apiKey: newApiKey,
					secretKey: newSecretKey,
					user_id: userId,
					isDefault: defaultBdId === null, // If no default selected, mark new entry as default
				})
				.then((response) => {
					setBrokers([
						...brokers,
						{
							...response.data,
							id: response.data.bd_id,
							enabled: false,
						},
					]);
					setNewApiKey("");
					setNewSecretKey("");
				})
				.catch((error) => {
					console.error("Failed to save new broker app details", error);
				});
		}
	};

	const handleDefaultChange = (bd_id: number) => {
		setDefaultBdId(bd_id);
	};

	const columns = [
		{
			field: "select",
			headerName: "",
			width: 30,
			renderCell: (params: any) => (
				<CenteredCell>
					<Checkbox
						checked={selectedIds.includes(params.row.bd_id)}
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedIds([...selectedIds, params.row.bd_id]);
							} else {
								setSelectedIds(selectedIds.filter((id) => id !== params.row.bd_id));
							}
						}}
					/>
				</CenteredCell>
			),
		},
		{
			field: "default",
			headerName: "Default",
			width: 80,
			renderCell: (params: any) => (
				<CenteredCell>
					<Radio checked={params.row.bd_id === defaultBdId} onChange={() => handleDefaultChange(params.row.bd_id)} />
				</CenteredCell>
			),
		},
		{
			field: "bd_id",
			headerName: "BD ID",
			width: 80,
			renderCell: (params: any) => <CenteredCell>{params.value}</CenteredCell>,
		},
		{
			field: "apiKey",
			headerName: "API Key",
			width: 110,
			renderCell: (params: any) => <CenteredCell>{StringUtils.maskString(params.value, 5, 5)}</CenteredCell>,
		},
		{
			field: "secretKey",
			headerName: "Secret Key",
			width: 110,
			renderCell: (params: any) => <CenteredCell>{StringUtils.maskString(params.value, 5, 5)}</CenteredCell>,
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 150,
			renderCell: (params: any) => (
				<CenteredCell>
					<Tooltip title="Edit">
						<Button onClick={() => setSelectedBroker(params.row)} size="small" style={{ marginRight: 8 }} variant="contained" color="primary">
							Edit
						</Button>
					</Tooltip>
					<Tooltip title="Delete">
						<Button
							onClick={() => {
								setDeleteBdId(params.row.bd_id);
								setConfirmDeleteOpen(true);
							}}
							size="small"
							variant="contained"
							color="secondary">
							Delete
						</Button>
					</Tooltip>
				</CenteredCell>
			),
		},
	];

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			PaperProps={{
				style: {
					width: "60%",
				},
			}}>
			<DialogTitle>Broker App Settings</DialogTitle>
			<DialogContent>
				<Typography variant="h6">Broker App Details</Typography>
				<div style={{ height: 400, width: "100%" }}>
					<DataGrid
						rows={brokers}
						columns={columns}
						getRowId={(row) => row.bd_id} // Use bd_id as the row id
					/>
				</div>
				<Typography variant="h6" style={{ marginTop: 20 }}>
					Add or Update Broker App Details
				</Typography>
				<TextField
					label="API Key"
					fullWidth
					margin="normal"
					value={selectedBroker?.apiKey || newApiKey}
					onChange={(e) => {
						if (selectedBroker) {
							setSelectedBroker({
								...selectedBroker,
								apiKey: e.target.value,
							});
						} else {
							setNewApiKey(e.target.value);
						}
					}}
				/>
				<TextField
					label="Secret Key"
					fullWidth
					margin="normal"
					value={selectedBroker?.secretKey || newSecretKey}
					onChange={(e) => {
						if (selectedBroker) {
							setSelectedBroker({
								...selectedBroker,
								secretKey: e.target.value,
							});
						} else {
							setNewSecretKey(e.target.value);
						}
					}}
				/>
			</DialogContent>
			<DialogActions>
				{selectedBroker ? (
					<>
						<Button onClick={() => handleUpdate(selectedBroker.bd_id)} color="primary" disabled={!isButtonEnabled}>
							Update
						</Button>
						<Button onClick={() => setSelectedBroker(null)}>Cancel</Button>
					</>
				) : (
					<Button onClick={handleSave} color="primary" disabled={!isButtonEnabled}>
						Save
					</Button>
				)}
				<Button onClick={onClose}>Close</Button>
				{selectedIds.length > 0 && (
					<Button onClick={handleBulkDelete} color="secondary">
						Bulk Delete
					</Button>
				)}
			</DialogActions>
			<Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete the selected broker(s)?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={confirmBulkDelete} color="primary">
						Confirm
					</Button>
					<Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
};

export default BrokerSettingsDialog;
