import { getDay, subDays } from "date-fns";

class DateUtils {
	public static getRecentFriday(date: Date): Date {
		const day = getDay(date);

		// If it's Saturday (6) or Sunday (0), adjust to Friday (5)
		if (day === 6) return subDays(date, 1); // Subtract 1 day if it's Saturday
		if (day === 0) return subDays(date, 2); // Subtract 2 days if it's Sunday

		// For all other days, return the same date (assuming it's before the weekend)
		return date;
	}
}

export default DateUtils;
