// ArrayUtils.ts

class ArrayUtils {
	/**
	 * Sorts an array of objects by a specified field in ascending or descending order.
	 * @param array - The array to sort.
	 * @param field - The field to sort by.
	 * @param ascending - True for ascending order, false for descending order.
	 * @returns The sorted array.
	 */
	public static sortByField<T>(
		array: T[],
		field: keyof T,
		ascending = true,
	): T[] {
		return array.slice().sort((a, b) => {
			const valueA = a[field];
			const valueB = b[field];

			if (valueA < valueB) return ascending ? -1 : 1;
			if (valueA > valueB) return ascending ? 1 : -1;
			return 0;
		});
	}

	/**
	 * Sorts an array of objects by a specified field in place.
	 * @param array - The array to sort.
	 * @param field - The field to sort by.
	 * @param ascending - True for ascending order, false for descending order.
	 */
	public static sortByFieldInPlace<T>(
		array: T[],
		field: keyof T,
		ascending = true,
	): void {
		array.sort((a, b) => {
			const valueA = a[field];
			const valueB = b[field];

			if (valueA < valueB) return ascending ? -1 : 1;
			if (valueA > valueB) return ascending ? 1 : -1;
			return 0;
		});
	}
}

export default ArrayUtils;
