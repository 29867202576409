import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface OrderGridProps {
	orderList: any[];
}

const OrderGrid: React.FC<OrderGridProps> = ({ orderList }) => {
	const [pageSize] = React.useState<number>(5);

	// Transform orderList to match the columns
	const rows = orderList.map((item, index) => ({
		id: index + 1,
		order_id: item.order_id,
		exchange_order_id: item.exchange_order_id,
		exchange_code: item.exchange_code,
		stock_code: item.stock_code,
		product_type: item.product_type,
		action: item.action,
		order_type: item.order_type,
		stoploss: item.stoploss,
		quantity: item.quantity,
		price: item.price,
		validity: item.validity,
		disclosed_quantity: item.disclosed_quantity,
		expiry_date: item.expiry_date,
		right: item.right,
		strike_price: item.strike_price,
		average_price: item.average_price,
		cancelled_quantity: item.cancelled_quantity,
		pending_quantity: item.pending_quantity,
		status: item.status,
		order_datetime: item.order_datetime,
		parent_order_id: item.parent_order_id || "",
		modification_number: item.modification_number || "",
		exchange_acknowledgement_date: item.exchange_acknowledgement_date || "",
		SLTP_price: item.SLTP_price || "",
		exchange_acknowledge_number: item.exchange_acknowledge_number || "",
		initial_limit: item.initial_limit || "",
		intial_sltp: item.intial_sltp || "",
		LTP: item.LTP || "",
		limit_offset: item.limit_offset || "",
		mbc_flag: item.mbc_flag || "",
		cutoff_price: item.cutoff_price || "",
		validity_date: item.validity_date || "",
	}));

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 50 },
		{ field: "order_id", headerName: "Order ID", width: 150 },
		{
			field: "exchange_order_id",
			headerName: "Exchange Order ID",
			width: 150,
		},
		{ field: "exchange_code", headerName: "Exchange Code", width: 100 },
		{ field: "stock_code", headerName: "Stock Code", width: 120 },
		{ field: "product_type", headerName: "Product Type", width: 120 },
		{ field: "action", headerName: "Action", width: 100 },
		{ field: "order_type", headerName: "Order Type", width: 120 },
		{ field: "quantity", headerName: "Quantity", width: 120 },
		{
			field: "price",
			headerName: "Price",
			width: 120,
			valueFormatter: ({ value }) =>
				value ? `₹ ${parseFloat(value).toLocaleString()}` : "",
		},
		{ field: "status", headerName: "Status", width: 120 },
		{
			field: "order_datetime",
			headerName: "Order Date & Time",
			width: 180,
		},
		{ field: "expiry_date", headerName: "Expiry Date", width: 120 },
		{ field: "strike_price", headerName: "Strike Price", width: 120 },
		{ field: "stoploss", headerName: "Stop Loss", width: 120 },
		{ field: "validity", headerName: "Validity", width: 120 },
		{
			field: "disclosed_quantity",
			headerName: "Disclosed Quantity",
			width: 180,
		},
		{ field: "average_price", headerName: "Average Price", width: 150 },
		{
			field: "cancelled_quantity",
			headerName: "Cancelled Quantity",
			width: 180,
		},
		{
			field: "pending_quantity",
			headerName: "Pending Quantity",
			width: 150,
		},
		{ field: "user_remark", headerName: "User Remark", width: 200 },
		{ field: "parent_order_id", headerName: "Parent Order ID", width: 150 },
		{
			field: "modification_number",
			headerName: "Modification Number",
			width: 180,
		},
		{
			field: "exchange_acknowledgement_date",
			headerName: "Exchange Acknowledgement Date",
			width: 200,
		},
		{ field: "SLTP_price", headerName: "SLTP Price", width: 120 },
		{
			field: "exchange_acknowledge_number",
			headerName: "Exchange Acknowledge Number",
			width: 180,
		},
		{ field: "initial_limit", headerName: "Initial Limit", width: 120 },
		{ field: "intial_sltp", headerName: "Initial SLTP", width: 120 },
		{ field: "LTP", headerName: "LTP", width: 120 },
		{ field: "limit_offset", headerName: "Limit Offset", width: 120 },
		{ field: "mbc_flag", headerName: "MBC Flag", width: 120 },
		{ field: "cutoff_price", headerName: "Cutoff Price", width: 120 },
		{ field: "validity_date", headerName: "Validity Date", width: 120 },
	];

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			{rows.length === 0 ? (
				<Typography variant="h6" align="center">
					No data available at the moment.
				</Typography>
			) : (
				<DataGrid
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: pageSize,
							},
						},
						columns: {
							columnVisibilityModel: {
								// stoploss: false,
								// validity: false,
								// disclosed_quantity: false,
								// average_price: false,
								// cancelled_quantity: false,
								// pending_quantity: false,
								// user_remark: false,
								// parent_order_id: false,
								// modification_number: false,
								// exchange_acknowledgement_date: false,
								// SLTP_price: false,
								// exchange_acknowledge_number: false,
								// initial_limit: false,
								// intial_sltp: false,
								// LTP: false,
								// limit_offset: false,
								// mbc_flag: false,
								// cutoff_price: false,
								// validity_date: false,
							},
						},
					}}
					pageSizeOptions={[5, 10, 20]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			)}
		</Box>
	);
};

export default OrderGrid;
