import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";
import moment from "moment";
import { DataUtils } from "../../../utils/DataUtils";
import { useTheme } from "../../../contexts/ThemeProviderContext";
import { ThemeType } from "../../../config/config";
import { useData } from "../../../contexts/DataProvider";
import styles from "./TradeGraph.module.scss";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

interface ITrade {
	stock: {
		code: string;
		index: number;
	};
	strike: {
		price: number;
		right: string;
		highest: number;
	};
	entry: {
		ltt: string;
		ltt_r: string;
		ltp: number;
	};
	exit: {
		ltt: string;
		ltt_r: string;
		ltp: number;
	};
	trail: number[];
	track: number[][];
}

interface TradeGraphProps {
	trades: ITrade[];
}

const TradeGraph: React.FC<TradeGraphProps> = ({ trades }) => {
	const { themeMode } = useTheme();
	const { appConfig } = useData();
	const [chartData, setChartData] = useState<any>(null);
	const [sortedTrades, setSortedTrades] = useState<ITrade[]>([]);
	const [startingQuarterInterval, setStartingQuarterInterval] = useState<number>(0);
	const [endingQuarterInterval, setEndingQuarterInterval] = useState<number>(0);

	useEffect(() => {
		if (!trades || trades.length === 0) return;

		// Sort trades by entry time in ascending order
		const sorted = trades.sort((a, b) => new Date(a.entry.ltt).getTime() - new Date(b.entry.ltt).getTime());
		setSortedTrades(sorted);

		// Get the first and last trade's entry time
		const firstTradeTime = new Date(sorted[0].entry.ltt);
		const lastTradeTime = new Date(sorted[sorted.length - 1].exit.ltt);

		// Calculate the nearest multiple of 15 minutes for the first trade (start) and last trade (end)
		setStartingQuarterInterval(new Date(Math.floor(firstTradeTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());
		setEndingQuarterInterval(new Date(Math.ceil(lastTradeTime.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)).getTime());

		// Set up chart data
		let cumulativePL = 0;
		let maxBuyPrice = 0;

		const dataPoints = sorted.map((trade) => {
			const buyPrice = trade.entry.ltp;
			const sellPrice = trade.exit.ltp;

			// Update max buy price if current buy price is higher
			maxBuyPrice = Math.max(maxBuyPrice, buyPrice);

			// Calculate the profit/loss for the current trade
			const pl = sellPrice - buyPrice;
			cumulativePL += pl;

			// Calculate % profit based on max buy price
			const percentProfit = ((cumulativePL / maxBuyPrice) * 100).toFixed(2);

			return {
				x: trade.entry.ltt_r, // Entry time
				y: parseFloat(percentProfit), // % profit based on max buy price
			};
		});

		// Insert the initial point at the start with y = 0
		const initialPoint = {
			x: startingQuarterInterval, // Use the minimum time (or any fixed value) as the x coordinate
			y: 0, // Start at 0
		};

		const chartDataPoints = [initialPoint, ...dataPoints];

		// Prepare data for the chart
		setChartData({
			datasets: [
				{
					label: "Cumulative P&L",
					data: chartDataPoints, // Use the modified data points
					fill: false,
					tension: 0.1,
					pointBorderColor: themeMode === ThemeType.dark ? "#fff" : "#000",
					pointBackgroundColor: "transparent",
					pointRadius: (ctx: { dataIndex: any; }) => {
						// Hide point at (0, 0)
						const pointIndex = ctx.dataIndex;
						return pointIndex === 0 ? 0 : 5; // Return 0 radius for the first point
					},
					segment: {
						borderColor: (ctx: { p1: { parsed: { y: any } }; p0: { parsed: { y: any } } }) => {
							const currentValue = ctx.p1.parsed.y;
							const previousValue = ctx.p0.parsed.y;
							return currentValue >= previousValue ? "#01fd0b" : "#dc004e";
						},
					},
				},
			],
		});
	}, [trades, themeMode]);

	// Format the start and end dates as DD-MMM
	const startDate = moment(sortedTrades[0]?.entry.ltt).format("DD-MMM hh:mm");
	const endDate = moment(sortedTrades[sortedTrades.length - 1]?.exit.ltt).format("DD-MMM hh:mm");

	return (
		<div className={styles["trade-graph"]}>
			{/* Chart header showing start and end date */}
			<div className={styles["chart-header"]}>{`Cumulative P&L% from ${startDate} to ${endDate}`}</div>
			{chartData && (
				<Line
					data={chartData}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								type: "time",
								min: startingQuarterInterval, // Set the minimum to the nearest 15-minute mark
								max: endingQuarterInterval, // Set the maximum to the nearest 15-minute mark
								time: {
									unit: "minute",
									tooltipFormat: appConfig?.timeFormats?.shortHm || "HH:mm",
									displayFormats: {
										minute: appConfig?.timeFormats?.shortHm || "HH:mm",
									},
								},
								title: {
									display: true,
									text: "Time (Entry)",
								},
								ticks: {
									stepSize: 15,
									source: "auto",
									autoSkip: true,
									callback: function (value) {
										const date = moment(value);
										return date.format(appConfig?.timeFormats?.shortHm || "HH:mm");
									},
								},
								grid: {
									color: themeMode === ThemeType.dark ? "#333" : "#e0e0e0",
								},
							},
							y: {
								title: {
									display: true,
									text: "Cumulative Profit/Loss (%)",
								},
								ticks: {
									padding: 13, // Add space between the y-axis labels and grid
								},
								grid: {
									color: (ctx) => {
										// Check if the line is at 0 to set a darker color
										return ctx.tick.value === 0 ? (themeMode === ThemeType.dark ? "#FFF" : "#000") : themeMode === ThemeType.dark ? "#333" : "#e0e0e0";
									},
									lineWidth: (ctx) => (ctx.tick.value === 0 ? 2 : 1), // Thicken the 0 line
								},
							},
						},
						plugins: {
							legend: {
								display: true,
								position: "top",
							},
							tooltip: {
								callbacks: {
									title: (tooltipItems) => {
										const firstItem = tooltipItems[0];
										const strategyKey = firstItem.dataset.label || "";
										const yValue = firstItem.parsed.y;
										const pointIndex = firstItem.dataIndex;

										const tradeInfo = DataUtils.getTradeInfo(pointIndex, sortedTrades, yValue);
										if (tradeInfo) {
											return `Strategy: ${strategyKey} : ${tradeInfo.calculatedProfit} (${yValue}%)`;
										}
										return ["Data not available"];
									},
									label: (tooltipItem) => {
										const pointIndex = tooltipItem.dataIndex - 1;

										const tradeInfo = DataUtils.getTradeInfo(pointIndex, sortedTrades);
										if (tradeInfo) {
											return [`${tradeInfo.point.strike.price} || ${tradeInfo.point.strike.right} || ${tradeInfo.profitPercentage}%`, `Max Entry LTP up to this point: ₹ ${tradeInfo.maxEntryPriceMultiplied.toFixed(2)}`];
										}
										return ["Data not available"];
									},
								},
							},
						},
					}}
				/>
			)}
		</div>
	);
};

export default TradeGraph;
