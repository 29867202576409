import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, isBefore, isAfter, getDay } from "date-fns";

import OrderGrid from "../../components/orderAndTrade/OrderGrid";
import TradeGrid from "../../components/orderAndTrade/TradeGrid";

import { useWebSocket } from "../../contexts/WebSocketContext";
import { clientRequestType, MessageType } from "../../config/config";

import ArrayUtils from "../../utils/ArrayUtils";

import styles from "./OrderAndTrade.module.scss";

interface OrderAndTradeProps {}

const OrderAndTrade: React.FC<OrderAndTradeProps> = ({}) => {
	const { sendMessage, receiveMessage, unsubscribeMessage, isConnected } = useWebSocket();
	const [orderList, setOrderList] = useState<any[]>([]);
	const [tradeList, setTradeList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// Default start and end times
	const defaultStartTime = setHours(setMinutes(new Date(), 15), 9); // 9:15 AM
	const defaultEndTime = setHours(setMinutes(new Date(), 30), 15); // 3:30 PM

	// State for date selection
	const [startDate, setStartDate] = useState<Date | null>(defaultStartTime);
	const [endDate, setEndDate] = useState<Date | null>(defaultEndTime);

	// Time constraints
	const startTime = setHours(setMinutes(new Date(), 15), 9);
	const endTime = setHours(setMinutes(new Date(), 30), 15);

	// Load data when mounts
	useEffect(() => {
		handleLoadData();
	}, []);

	// Handle incoming WebSocket messages
	useEffect(() => {
		const handleMessage = (message: any) => {
			if (message.type === MessageType.orderList) {
				message.data?.Success && setOrderList(ArrayUtils.sortByField(message.data.Success, "order_id"));
			} else if (message.type === MessageType.tradeList) {
				message.data?.Success && setTradeList(ArrayUtils.sortByField(message.data.Success, "order_id"));
			}
			setIsLoading(false);
		};

		receiveMessage(handleMessage);
		return () => {
			unsubscribeMessage(handleMessage);
		};
	}, [receiveMessage, unsubscribeMessage]);

	// Handle date changes with restrictions
	const handleDateChange = (date: Date | null, setter: React.Dispatch<React.SetStateAction<Date | null>>) => {
		if (date) {
			const selectedTime = setHours(setMinutes(new Date(), date.getMinutes()), date.getHours());
			if (isBefore(selectedTime, startTime)) {
				const adjustedDate = setHours(setMinutes(date, 15), 9); // Set to 9:15 AM
				setter(adjustedDate);
			} else if (isAfter(selectedTime, endTime)) {
				const adjustedDate = setHours(setMinutes(date, 30), 15); // Set to 3:30 PM
				setter(adjustedDate);
			} else {
				setter(date); // Valid time
			}
		} else {
			setter(null); // Reset
		}
	};

	// Disable time outside of valid range
	const filterTime = (time: Date) => {
		const currentTime = setHours(setMinutes(new Date(), time.getMinutes()), time.getHours());
		return isAfter(currentTime, startTime) && isBefore(currentTime, endTime);
	};

	// Disable weekends
	const filterWeekdays = (date: Date) => {
		const day = getDay(date);
		return day !== 0 && day !== 6; // Disable Saturday (6) and Sunday (0)
	};

	// Load data based on selected date range
	const handleLoadData = () => {
		if (startDate && endDate) {
			setIsLoading(true); // Show loading state while data is being fetched
			sendMessage({
				type: clientRequestType.orderList,
				data: {
					startDate,
					endDate,
				},
			});
			sendMessage({
				type: clientRequestType.tradeList,
				data: {
					startDate,
					endDate,
				},
			});
		}
	};

	return (
		<div className={styles.container}>
			<Typography variant="h2">Order and Trade Manager</Typography>
			<div>
				<div className={styles.form}>
					<div>Select Date Range</div>
					<div>
						<Typography>Start Date</Typography>
						<DatePicker
							selected={startDate}
							onChange={(date) => handleDateChange(date, setStartDate)}
							showTimeSelect
							timeIntervals={15}
							filterDate={filterWeekdays}
							filterTime={filterTime}
							dateFormat="Pp"
							timeCaption="Time"
						/>
					</div>
					<div>
						<Typography>End Date</Typography>
						<DatePicker
							selected={endDate}
							onChange={(date) => handleDateChange(date, setEndDate)}
							showTimeSelect
							timeIntervals={15}
							filterDate={filterWeekdays}
							filterTime={filterTime}
							dateFormat="Pp"
							timeCaption="Time"
						/>
					</div>
					<Button size="small" variant="contained" onClick={handleLoadData}>
						Load Data
					</Button>
				</div>
				{isLoading ? (
					<Typography>Loading data...</Typography>
				) : (
					<>
						<Typography variant="h6">Order List</Typography>
						<OrderGrid orderList={orderList} />

						<Typography variant="h6" style={{ marginTop: 20 }}>
							Trade List
						</Typography>
						<TradeGrid tradeList={tradeList} />
					</>
				)}
			</div>
		</div>
	);
};

export default OrderAndTrade;
