import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useWebSocket } from "./WebSocketContext";
import { clientRequestType, MessageType, TradeType } from "../config/config";
import { useStockSubscription } from "./StockSubscriptionContext";

interface TradingContextType {
	isTrading: boolean;
	tradeMode: string;
	positionOpened: boolean;
	// eslint-disable-next-line no-unused-vars
	tradeModeChange: (mode: string) => void;
	tradeStart: (mode: string) => void;
	tradeEnd: () => void;
}

const TradingContext = createContext<TradingContextType | undefined>(undefined);

export const TradingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isConnected, sendMessage, receiveMessage } = useWebSocket();
	const { isSubscribed } = useStockSubscription();

	const [isTrading, setIsTrading] = useState<boolean>(false);
	const [tradeMode, setTradeMode] = useState<string>(TradeType.paper);
	const [positionOpened, setPositionOpened] = useState<boolean>(false);

	useEffect(() => {
		receiveMessage((message) => {
			switch (message.type) {
				case MessageType.tradeModeChanged:
					setTradeMode(message.data.mode);
					break;
				case MessageType.tradeStarted:
					setIsTrading(true);
					break;
				case MessageType.tradeEnded:
					setIsTrading(false);
					break;
				case MessageType.positionOpened:
					setPositionOpened(true);
					break;
				case MessageType.positionClosed:
					setPositionOpened(false);
					break;
			}
		});
	}, [receiveMessage]);

	const tradeModeChange = useCallback(
		(mode: string) => {
			if (isConnected && isSubscribed) {
				sendMessage({
					type: clientRequestType.tradeModeChange,
					data: { mode: mode },
				});
			}
		},
		[isConnected, isSubscribed, isTrading]
	);

	const tradeStart = useCallback(
		(mode: string) => {
			if (isConnected && isSubscribed) {
				sendMessage({
					type: clientRequestType.tradeStart,
					data: { mode: mode },
				});
			}
		},
		[isConnected, isSubscribed]
	);

	const tradeEnd = useCallback(() => {
		if (isConnected && isSubscribed) {
			sendMessage({
				type: clientRequestType.tradeEnd,
				data: {},
			});
		}
	}, [isConnected, isSubscribed]);

	useEffect(() => {
		if (!isConnected || !isSubscribed) {
			setIsTrading(false);
		}
	}, [isConnected, isSubscribed]);

	return <TradingContext.Provider value={{ isTrading, tradeMode, tradeModeChange, positionOpened, tradeStart, tradeEnd }}>{children}</TradingContext.Provider>;
};

export const useTrading = () => {
	const context = useContext(TradingContext);
	if (context === undefined) {
		throw new Error("useTrading must be used within a TradingProvider");
	}
	return context;
};
