import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	MenuItem,
	TextField,
	CircularProgress,
	Autocomplete,
} from "@mui/material";
import { useData } from "../../contexts/DataProvider";
import { useWebSocket } from "../../contexts/WebSocketContext";
import { clientRequestType } from "../../config/config";

interface WindowOrderDialogProps {
	open: boolean;
	onClose: () => void;
}

const WindowOrderDialog: React.FC<WindowOrderDialogProps> = ({
	open,
	onClose,
}) => {
	const { appConfig } = useData();
	const { sendMessage, isConnected } = useWebSocket();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [stockKey, setStockKey] = useState<string>("");
	const [rightType, setRightType] = useState<string>("");
	const [strikePrice, setStrikePrice] = useState<number | null>(null);
	const [strikePriceOptions, setStrikePriceOptions] = useState<number[]>([]);
	const [orderType, setOrderType] = useState<string>("buy");

	useEffect(() => {
		if (!isConnected) {
			onClose();
		}
	}, [isConnected]);

	useEffect(() => {
		if (appConfig && appConfig.stockKey && appConfig.rightType) {
			setStockKey(appConfig.defaultStockKey ?? "");
			setRightType(appConfig.rightType.none ?? "None");
			setIsLoading(false);
		}
	}, [appConfig]);

	useEffect(() => {
		if (stockKey && appConfig) {
			let defaultStrikePrice = 25000; // Default for "nifty"
			if (stockKey === "bankNifty") {
				defaultStrikePrice = 52000; // Default for "bankNifty"
			}

			const step = appConfig.strikePriceStep[stockKey] || 50; // Default step
			const depth = step * 25;
			const options = [];
			for (
				let i = defaultStrikePrice - depth;
				i <= defaultStrikePrice + depth;
				i += step
			) {
				options.push(i);
			}
			setStrikePrice(defaultStrikePrice);
			setStrikePriceOptions(options);
		}
	}, [stockKey, appConfig]);

	const handleStockKeyChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setStockKey(event.target.value);
	};

	const handleRightTypeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRightType(event.target.value);
	};

	const handleStrikePriceChange = (event: any, value: number | null) => {
		setStrikePrice(value);
	};

	const handleOrderTypeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setOrderType(event.target.value);
	};

	const handleSubmit = () => {
		const startDate = new Date();
		const endDate = new Date();

		sendMessage({
			type:
				orderType === "buy"
					? clientRequestType.placeOrder
					: clientRequestType.squareOffOrder,
			data: { startDate, endDate, stockKey, rightType, strikePrice },
		});

		onClose();
	};

	if (isLoading) {
		return (
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>Loading...</DialogTitle>
				<DialogContent>
					<CircularProgress />
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Place Order</DialogTitle>
			<DialogContent>
				<TextField
					select
					label="Stock Key"
					value={stockKey}
					onChange={handleStockKeyChange}
					fullWidth
					margin="normal"
				>
					{Object.entries(appConfig.stockKey).map(([key, value]) => (
						<MenuItem key={key} value={value as string}>
							{key}
						</MenuItem>
					))}
				</TextField>

				<TextField
					select
					label="Right Type"
					value={rightType}
					onChange={handleRightTypeChange}
					fullWidth
					margin="normal"
				>
					{Object.entries(appConfig.rightType).map(([key, value]) => (
						<MenuItem key={key} value={value as string}>
							{key}
						</MenuItem>
					))}
				</TextField>

				<Autocomplete
					options={strikePriceOptions}
					getOptionLabel={(option) => option.toString()}
					value={strikePrice}
					onChange={handleStrikePriceChange}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Strike Price"
							fullWidth
							margin="normal"
						/>
					)}
				/>

				<TextField
					select
					label="Order Type"
					value={orderType}
					onChange={handleOrderTypeChange}
					fullWidth
					margin="normal"
				>
					<MenuItem value="buy">Buy</MenuItem>
					<MenuItem value="sell">Sell</MenuItem>
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleSubmit} color="primary">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default WindowOrderDialog;
