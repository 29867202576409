import React, { useState } from "react";
import { Button, Input, Typography } from "@mui/material";
import styles from "./ImportReportForm.module.scss";

interface ImportReportFormProps {
	// eslint-disable-next-line no-unused-vars
	setData: (data: any) => void; // Function to update data in parent
}

const ImportReportForm: React.FC<ImportReportFormProps> = ({ setData }) => {
	const [selectedFile, setSelectedFile] = useState<string | null>(null);

	const handleFileImport = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setSelectedFile(file.name); // Update state to show the selected file name

			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					const jsonData = JSON.parse(e.target?.result as string);
					console.log("jsonData", jsonData);
					setData(jsonData); // Pass the loaded data to the parent
				} catch (error) {
					console.error("Invalid JSON format:", error);
				}
			};
			reader.readAsText(file);
		}
	};

	return (
		<div className={styles["import-report"]}>
			<Input
				type="file"
				onChange={handleFileImport}
				inputProps={{
					accept: ".json", // Restrict file types
					style: { display: "none" },
				}}
				id="file-input"
			/>

			{/* Display the selected file path if available */}
			{selectedFile && (
				<Typography
					variant="body2"
					color="textSecondary"
					style={{ marginTop: "10px" }}
				>
					Selected File: {selectedFile}
				</Typography>
			)}

			<label htmlFor="file-input">
				<Button variant="contained" color="primary" component="span">
					Import JSON
				</Button>
			</label>
		</div>
	);
};

export default ImportReportForm;
