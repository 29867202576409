import React, { createContext, useState, useEffect, ReactNode, useContext } from "react";
import axios from "axios";
import config from "../config/config";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
	isAuthenticated: boolean;
	isVendorAuthenticated: boolean;
	// eslint-disable-next-line no-unused-vars
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [isVendorAuthenticated, setIsVendorAuthenticated] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		// Check URL for 'apisession' parameter and store it in sessionStorage
		const urlParams = new URLSearchParams(window.location.search);
		const apiSession = urlParams.get("apisession");

		if (apiSession) {
			sessionStorage.setItem("session_token", apiSession);
			moveToHome();
		}

		const vendorToken = sessionStorage.getItem("session_token");
		if (vendorToken) {
			setIsVendorAuthenticated(true);
		} else {
			moveToHome();
		}

		const token = sessionStorage.getItem("token");
		if (token) {
			setIsAuthenticated(true);
		}
	}, [navigate]);

	const moveToHome = () => {
		if (location.pathname !== "/home") {
			navigate("/home");
		}
	};

	const login = async (email: string, password: string) => {
		try {
			const response = await axios.post(config.apis.user.login, {
				email,
				password,
			});
			const { token, user } = response.data;

			// Save token in sessionStorage
			sessionStorage.setItem("token", token);

			// Save user details in localStorage
			localStorage.setItem("user_id", user.user_id);
			localStorage.setItem("username", user.name);
			localStorage.setItem("email", user.email);

			setIsAuthenticated(true);
		} catch (error) {
			alert("Login failed. Please check your credentials.");
		}
	};

	const logout = () => {
		sessionStorage.clear();
		localStorage.clear();
		setIsAuthenticated(false);
		navigate("/login");
	};

	return <AuthContext.Provider value={{ isAuthenticated, isVendorAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useStockSubscription must be used within a StockSubscriptionProvider");
	}
	return context;
};
