// FetchReportForm.tsx
import React, { useEffect, useState } from "react";
import { Button, Slider, Tooltip, Checkbox, FormControlLabel, Select, MenuItem, Switch } from "@mui/material";
import DatePicker from "react-datepicker";

import { setHours, setMinutes, isBefore, isAfter, getDay } from "date-fns";
import axios from "axios";
import config from "../../../config/config";
import { useData } from "../../../contexts/DataProvider";
import styles from "./ReportForm.module.scss";
import DateUtils from "../../../utils/DateUtils";

// Define a type for the prop
interface ReportFormProps {
	// eslint-disable-next-line no-unused-vars
	setData: (data: any) => void; // Function to update data in parent
	fetchingFreshData: () => void;
}

const ReportForm: React.FC<ReportFormProps> = ({ setData, fetchingFreshData }) => {
	const { appConfig } = useData();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formDisabled, setFormDisabled] = useState(false);

	const [selectedStockCode, setSelectedStockCode] = useState<string>("");

	const today = new Date();
	const lastFriday = DateUtils.getRecentFriday(today);

	// Default times
	const defaultStartTime = setHours(setMinutes(lastFriday, 15), 9); // 9:15 AM
	const defaultEndTime = setHours(setMinutes(lastFriday, 30), 15); // 3:30 PM

	// State variables
	const [startDate, setStartDate] = useState<Date | null>(defaultStartTime);
	const [endDate, setEndDate] = useState<Date | null>(defaultEndTime);

	const [priceHikePercentage, setPriceHikePercentage] = useState<number>(1);
	const [priceHikePercentageRange, setPriceHikePercentageRange] = useState<number[]>([0.55, 0.65]);
	const [priceHikePercentageStep, setPriceHikePercentageStep] = useState(0.05);

	const [stopLoss, setStopLoss] = useState<number>(15);
	const [stopLossRange, setStopLossRange] = useState<number[]>([5.5, 6.5]);
	const [stopLossStep, setStopLossStep] = useState(0.5);

	const [continuity, setContinuity] = useState<number>(5);
	const [continuityRange, setContinuityRange] = useState<number[]>([2, 3]);
	const [continuityStep, setContinuityStep] = useState<number>(1);

	const [intervalEntryStep, setIntervalEntryStep] = useState<number>(10);
	const [intervalExitStep, setIntervalExitStep] = useState<number>(10);

	const [logs, setLogs] = useState<boolean>(false);
	const [isRange, setIsRange] = useState<boolean>(false);

	type Strategies = {
		downFallEntry: boolean;
		lazy: boolean;
		projectile: boolean;
		steepDown: boolean;
	};

	const [strategies, setStrategies] = useState<Strategies>({
		downFallEntry: false,
		lazy: false,
		projectile: false,
		steepDown: false,
	});

	// effects
	useEffect(() => {
		setFormDisabled(isSubmitting || selectedStockCode === "");
	}, [isSubmitting, selectedStockCode]);

	// Add this useEffect to set the first stock code as default
	useEffect(() => {
		if (appConfig.stockCode) {
			const firstStockValue = Object.values(appConfig.stockCode)[0];
			setSelectedStockCode(firstStockValue as string); // Set the first stock code by default
		}
	}, [appConfig.stockCode]);

	// Time constraints
	const startTime = setHours(setMinutes(new Date(), 15), 9); // 9:15 AM
	const endTime = setHours(setMinutes(new Date(), 30), 15); // 3:30 PM

	const updateFormData = (data: any) => {
		setPriceHikePercentageRange(data[0]);
		setPriceHikePercentageStep(data[1]);
		setStopLossRange(data[2]);
		setStopLossStep(data[3]);
		setContinuityRange(data[4]);
		setContinuityStep(data[5]);
		setIntervalEntryStep(data[6]);
		setIntervalExitStep(data[7]);
	};

	// Preset logic
	const applyDefaults = (preset: "inDepth" | "detailed" | "ideal" | "best") => {
		const analysisData = {
			inDepth: [[0.1, 0.8], 0.1, [1, 10], 0.5, [1, 5], 1, 10, 10],
			detailed: [[0.4, 0.7], 0.1, [3, 7], 0.5, [2, 3], 1, 10, 10],
			ideal: [[0.55, 0.65], 0.05, [5.5, 6.5], 0.5, [2, 3], 1, 10, 10],
			best: [[1, 1], 0.05, [15, 15], 0.25, [5, 5], 1, 60, 60],
		};
		updateFormData(analysisData[preset]);
	};

	const handleDateChange = (date: Date | null, setter: React.Dispatch<React.SetStateAction<Date | null>>) => {
		if (date) {
			const selectedTime = setHours(setMinutes(new Date(), date.getMinutes()), date.getHours());
			if (isBefore(selectedTime, startTime)) {
				setter(setHours(setMinutes(date, 15), 9)); // 9:15 AM
			} else if (isAfter(selectedTime, endTime)) {
				setter(setHours(setMinutes(date, 30), 15)); // 3:30 PM
			} else {
				setter(date);
			}
		} else {
			setter(null);
		}
	};

	const filterTime = (time: Date) => {
		const currentTime = setHours(setMinutes(new Date(), time.getMinutes()), time.getHours());
		return (isAfter(currentTime, startTime) && isBefore(currentTime, endTime)) || (currentTime.getHours() === 9 && currentTime.getMinutes() === 15) || (currentTime.getHours() === 15 && currentTime.getMinutes() === 30);
	};

	const filterWeekdays = (date: Date) => {
		const day = getDay(date);
		return day !== 0 && day !== 6; // Disable weekends
	};

	const handleCheckboxChange = (event: { target: { name: any; checked: any } }) => {
		setStrategies({
			...strategies,
			[event.target.name]: event.target.checked,
		});
	};

	// Handle submit and pass data to parent
	const handleSubmit = async () => {
		// Filter selected strategies
		const selectedStrategies = Object.keys(strategies)
			.filter((key) => strategies[key as keyof Strategies]) // Type assertion here
			.map((key) => key);

		const payload = {
			stockCode: selectedStockCode,

			startDate,
			endDate,

			continuityRange: isRange ? continuityRange : [continuity, continuity],
			priceHikePercentageRange: isRange ? priceHikePercentageRange : [priceHikePercentage, priceHikePercentage],
			stopLossRange: isRange ? stopLossRange : [stopLoss, stopLoss],

			continuityStep,
			stopLossStep,
			priceHikePercentageStep,

			intervalEntryStep,
			intervalExitStep,

			strategies: selectedStrategies,

			logs,
		};

		try {
			setIsSubmitting(true); // Disable the submit button
			fetchingFreshData();
			const response = await axios.post(`${config.apis.analyze}`, payload);
			console.log("API response:", response.data);
			setData(response.data);
		} catch (error) {
			console.error("API call failed:", error);
		} finally {
			setIsSubmitting(false); // Enable the submit button after the data is received
		}
	};

	return (
		<div className={styles["report-form"]}>
			<div className={styles["stratergy-buttons"]}>
				{/* Preset Buttons */}
				<div className={styles["stratergy-button"]}>
					<Button variant="contained" color="secondary" disabled={formDisabled || !isRange} onClick={() => applyDefaults("inDepth")}>
						In Depth Analysis
					</Button>
				</div>
				<div className={styles["stratergy-button"]}>
					<Button variant="contained" color="error" disabled={formDisabled || !isRange} onClick={() => applyDefaults("detailed")}>
						Detailed Analysis
					</Button>
				</div>
				<div className={styles["stratergy-button"]}>
					<Button variant="contained" color="primary" disabled={formDisabled || !isRange} onClick={() => applyDefaults("ideal")}>
						Ideal Analysis
					</Button>
				</div>
				<div className={styles["stratergy-button"]}>
					<Button variant="contained" color="success" disabled={formDisabled} onClick={() => applyDefaults("best")}>
						To-The-Point Analysis
					</Button>
				</div>
			</div>

			<div className={styles["grid-container"]}>
				{/* Row 1: Stratergies */}
				<div className={styles["grid-item"]}>Stratergies </div>
				<div className={`${styles["grid-item"]} ${styles["span-row2-1"]}`}>
					<Tooltip title="Entered by mistake? When the stock slips at the second trailing point, it loses 50% of the time. Why risk further losses?">
						<FormControlLabel control={<Checkbox name="downFallEntry" checked={strategies.downFallEntry} onChange={handleCheckboxChange} />} label="Down fall entry" disabled={formDisabled} />
					</Tooltip>

					<Tooltip title="Reached a certain height but neither increasing nor decreasing. Why wait for it to hit the stop-loss?">
						<FormControlLabel control={<Checkbox name="projectile" checked={strategies.projectile} onChange={handleCheckboxChange} />} label="Projectile" disabled={formDisabled} />
					</Tooltip>

					<Tooltip title="Even after 10 hits, price is below than entry by 1%">
						<FormControlLabel control={<Checkbox name="lazy" checked={strategies.lazy} onChange={handleCheckboxChange} />} label="Lazy" disabled={formDisabled} />
					</Tooltip>

					<Tooltip title="Reached a certain height but neither increasing nor decreasing. Why wait for it to hit the stop-loss?">
						<FormControlLabel control={<Checkbox name="steepDown" checked={strategies.steepDown} onChange={handleCheckboxChange} />} label="Steep Down" disabled={formDisabled} />
					</Tooltip>
				</div>

				{/* Row 2 */}
				<div className={styles["grid-item"]}>Date & Time</div>
				<div className={styles["grid-item"]}>Start</div>
				<div className={styles["grid-item"]}>
					<DatePicker
						selected={startDate}
						onChange={(date) => handleDateChange(date, setStartDate)}
						showTimeSelect
						timeIntervals={15}
						filterDate={filterWeekdays} // Disable weekends
						filterTime={filterTime} // Restrict time selection
						dateFormat="Pp"
						timeCaption="Time"
						disabled={formDisabled}
					/>
				</div>
				<div className={styles["grid-item"]}>End</div>
				<div className={styles["grid-item"]}>
					<DatePicker
						selected={endDate}
						onChange={(date) => handleDateChange(date, setEndDate)}
						showTimeSelect
						timeIntervals={15}
						filterDate={filterWeekdays} // Disable weekends
						filterTime={filterTime} // Restrict time selection
						dateFormat="Pp"
						timeCaption="Time"
						disabled={formDisabled}
					/>
				</div>

				{/* Row 5: Continuity */}
				<div className={styles["grid-item"]}>Continuity</div>
				<div className={styles["grid-item"]}>{isRange ? `Range (${continuityRange[0].toFixed(0)} - ${continuityRange[1].toFixed(0)})` : `Value (${continuity.toFixed(0)})`}</div>
				<div className={styles["grid-item"]}>
					<Slider value={isRange ? continuityRange : continuity} min={1} max={10} step={1} onChange={(event, newValue) => (isRange ? setContinuityRange(newValue as number[]) : setContinuity(newValue as number))} valueLabelDisplay="auto" disabled={formDisabled} />
				</div>
				<div className={styles["grid-item"]}>Step ({continuityStep.toFixed(0)})</div>
				<div className={styles["grid-item"]}>
					<Slider value={continuityStep} min={1} max={5} step={1} onChange={(event, newValue) => setContinuityStep(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled || !isRange} />
				</div>

				{/* Row 3 */}
				<div className={styles["grid-item"]}>Price Action (High: Strict Entry)</div>
				<div className={styles["grid-item"]}>{isRange ? `Range (${priceHikePercentageRange[0].toFixed(2)}% - ${priceHikePercentageRange[1].toFixed(2)}%)` : `Value (${priceHikePercentage.toFixed(2)}%)`}</div>
				<div className={styles["grid-item"]}>
					<Slider
						value={isRange ? priceHikePercentageRange : priceHikePercentage}
						min={0.05}
						max={5}
						step={0.05}
						onChange={(event, newValue) => (isRange ? setPriceHikePercentageRange(newValue as number[]) : setPriceHikePercentage(newValue as number))}
						valueLabelDisplay="auto"
						valueLabelFormat={(value) => value.toFixed(2)}
						aria-labelledby="range-slider"
						getAriaValueText={(value) => `${value.toFixed(2)}`}
						disabled={formDisabled}
					/>
				</div>
				<div className={styles["grid-item"]}>Step ({priceHikePercentageStep.toFixed(2)})</div>
				<div className={styles["grid-item"]}>
					<Slider value={priceHikePercentageStep} min={0.05} max={0.5} step={0.05} onChange={(event, newValue) => setPriceHikePercentageStep(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled || !isRange} />
				</div>

				{/* Row 3 */}
				<div className={styles["grid-item"]}>Interval</div>
				<div className={styles["grid-item"]}>Entry ({intervalEntryStep.toFixed(0)}secs)</div>
				<div className={styles["grid-item"]}>
					<Slider value={intervalEntryStep} min={10} max={120} step={10} onChange={(event, newValue) => setIntervalEntryStep(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled} />
				</div>
				<div className={styles["grid-item"]}>Exit ({intervalExitStep.toFixed(0)}secs)</div>
				<div className={styles["grid-item"]}>
					<Slider value={intervalExitStep} min={10} max={120} step={10} onChange={(event, newValue) => setIntervalExitStep(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled} />
				</div>

				{/* Row 4 */}
				<div className={styles["grid-item"]}>Stop Loss (Low: Easy Exit)</div>
				<div className={styles["grid-item"]}>{isRange ? `Range (${stopLossRange[0].toFixed(2)}% - ${stopLossRange[1].toFixed(2)}%)` : `Value (${stopLoss.toFixed(2)})`}</div>
				<div className={styles["grid-item"]}>
					<Slider
						value={isRange ? stopLossRange : stopLoss}
						min={0.25}
						max={20}
						step={0.25}
						onChange={(event, newValue) => (isRange ? setStopLossRange(newValue as number[]) : setStopLoss(newValue as number))}
						valueLabelDisplay="auto"
						valueLabelFormat={(value) => value.toFixed(2)}
						aria-labelledby="range-slider"
						getAriaValueText={(value) => `${value.toFixed(2)}`}
						disabled={formDisabled}
					/>
				</div>
				<div className={styles["grid-item"]}>Step ({stopLossStep.toFixed(2)})</div>
				<div className={styles["grid-item"]}>
					<Slider value={stopLossStep} min={0.25} max={5} step={0.25} onChange={(event, newValue) => setStopLossStep(newValue as number)} valueLabelDisplay="auto" disabled={formDisabled || !isRange} />
				</div>

				{/* Row 6: Stock */}
				<div className={styles["grid-item"]}>Stock </div>
				<div className={`${styles["grid-item"]} ${styles["span-row2-3"]}`}>
					<Select labelId="stock-code-label" value={selectedStockCode} onChange={(event) => setSelectedStockCode(event.target.value as string)} size="small" fullWidth disabled={isSubmitting}>
						{Object.entries(appConfig.stockCode).map(([key, value]) => (
							<MenuItem key={key} value={value as string}>
								{`${key} (${value})`}
							</MenuItem>
						))}
					</Select>
				</div>
				<div className={styles["grid-item"]}> </div>
				<div className={`${styles["grid-item"]} ${styles["justify-content"]}`}>
					<FormControlLabel control={<Switch onChange={() => setLogs(!logs)} disabled={isSubmitting} />} label="Backend Logs" labelPlacement="end" />
					<FormControlLabel control={<Switch onChange={() => setIsRange(!isRange)} disabled={isSubmitting} />} label="Range" labelPlacement="end" />
				</div>

				<div className={`${styles["grid-item"]} ${styles["span-row1-1"]} ${styles["center-align"]}`}>
					<Button variant="contained" color="primary" onClick={handleSubmit} disabled={formDisabled}>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ReportForm;
