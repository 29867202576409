import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useWebSocket } from "./WebSocketContext";
import { MessageType } from "../config/config";
import IAppConfig from "../interfaces/IAppConfig";
import { IUserConfig } from "../interfaces/IUserConfig";

// Define the type for the context value
interface DataContextType {
	appConfig: IAppConfig;
	userConfig: IUserConfig;
}

// Create a context with default value of `null`
const DataContext = createContext<DataContextType | null>(null);

// Create a provider component
export const DataProvider = ({ children }: { children: ReactNode }) => {
	const { receiveMessage } = useWebSocket();
	const [appConfig, setAppConfig] = useState<any>({});
	const [userConfig, setUserConfig] = useState<any>({});

	useEffect(() => {
		receiveMessage((message) => {
			switch (message.type) {
				case MessageType.initialConfig:
					setAppConfig(message.data);
					break;
				case MessageType.userConfig:
				case MessageType.configResetted:
				case MessageType.configUpdated:
					setUserConfig(message.data);
					break;
			}
		});
	}, [receiveMessage]);

	return <DataContext.Provider value={{ appConfig, userConfig }}>{children}</DataContext.Provider>;
};

// Custom hook to use the context safely
export const useData = (): DataContextType => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error("useData must be used within a DataProvider");
	}
	return context;
};
