export class StringUtils {
	static maskString = (
		str: string,
		startLength: number,
		endLength: number,
	): string => {
		if (!str || str.length <= startLength + endLength) {
			return str;
		}
		return `${str.substring(0, startLength)}...${str.substring(str.length - endLength)}`;
	};

	static hasError = (data: string | null): boolean => {
		if (data === null) return false; // Handle null case

		// Check for "error" in the string
		const isErrorPresent = data.toLowerCase().includes("error");

		// Try parsing the string as JSON
		try {
			const jsonData = JSON.parse(data);

			// Check if the parsed object has an "Error" field that is null
			if (jsonData.Error === null) return false;
		} catch {
			// If parsing fails, we treat it as a normal string check
			// No additional action needed here
		}

		// Return true if an error is present in the string
		return isErrorPresent;
	};
}
