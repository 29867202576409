import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { useWebSocket } from "../../../contexts/WebSocketContext";
import { useStockSubscription } from "../../../contexts/StockSubscriptionContext";
import { MessageType } from "../../../config/config";
import { SwapVerticalCircle } from "@mui/icons-material";
import styles from "./OptionChainIndicator.module.scss";

const OptionChainIndicator: React.FC = () => {
	const { isAuthenticated } = useAuth();
	const { isSubscribed } = useStockSubscription();
	const { isConnected, receiveMessage } = useWebSocket();

	const [showIndicator, setShowIndicator] = useState(false);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setShowIndicator(isAuthenticated && isConnected && isSubscribed);
	}, [isAuthenticated, isConnected, isSubscribed]);

	useEffect(() => {
		// eslint-disable-next-line no-undef
		let timeoutId: NodeJS.Timeout;

		if (isConnected && isSubscribed) {
			receiveMessage((message) => {
				const isLiveStrikeFeed = message.type === MessageType.liveStrike;
				if (isLiveStrikeFeed && Object.keys(message.data).length > 1) {
					setIsActive(true);
					clearTimeout(timeoutId);

					timeoutId = setTimeout(() => {
						setIsActive(false);
					}, 12000);
				}
			});
		}

		return () => clearTimeout(timeoutId);
	}, [receiveMessage, isConnected, isSubscribed]);

	return showIndicator ? (
		<div className={`${styles["option-chain-indicator"]} ${isActive ? "active" : ""}`}>
			<Box className={styles.icon} title={isActive ? "Option chain is Gold!" : "Please complain, option chains are stolen!"}>
				<SwapVerticalCircle fontSize="small" className={isActive ? styles.increased : styles.decreased} />
			</Box>
		</div>
	) : null;
};

export default OptionChainIndicator;
