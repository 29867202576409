import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from "@mui/material";

interface UserSettingsDialogProps {
	open: boolean;
	onClose: () => void;
}

const UserSettingsDialog: React.FC<UserSettingsDialogProps> = ({
	open,
	onClose,
}) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>User Settings</DialogTitle>
			<DialogContent>
				<Typography>
					User settings (username, email, password etc) content goes
					here.
				</Typography>
				{/* Insert User Settings Content Here */}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserSettingsDialog;
