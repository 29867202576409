import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface TradeGridProps {
	tradeList: any[];
}

const TradeGrid: React.FC<TradeGridProps> = ({ tradeList }) => {
	const [pageSize] = React.useState<number>(5);

	// Transform tradeList to match the columns
	const rows = tradeList.map((item, index) => ({
		id: index + 1,
		order_id: item.order_id,
		exchange_code: item.exchange_code,
		stock_code: item.stock_code,
		product_type: item.product_type,
		action: item.action,
		order_type: item.order_type || "", // Placeholder
		quantity: item.quantity,
		expiry_date: item.expiry_date,
		strike_price: item.strike_price,
		LTP: item.ltp,
		price: item.average_cost,
		book_type: item.book_type || "", // Placeholder
		brokerage_amount: item.brokerage_amount || "", // Placeholder
		segment: item.segment || "", // Placeholder
		settlement_code: item.settlement_code || "", // Placeholder
		dp_id: item.dp_id || "", // Placeholder
		client_id: item.client_id || "", // Placeholder
		eatm_withheld_amount: item.eatm_withheld_amount || "", // Placeholder
		cash_withheld_amount: item.cash_withheld_amount || "", // Placeholder
		total_taxes: item.total_taxes || "", // Placeholder
		order_datetime: item.trade_date,
		right: item.right,
	}));

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", width: 50 },
		{ field: "order_id", headerName: "Order ID", width: 150 },
		{ field: "expiry_date", headerName: "Expiry Date", width: 120 },
		{
			field: "order_datetime",
			headerName: "Order Date & Time",
			width: 180,
		},
		{ field: "stock_code", headerName: "Stock Code", width: 120 },
		{ field: "action", headerName: "Action", width: 100 },
		{ field: "order_type", headerName: "Order Type", width: 120 },
		{ field: "quantity", headerName: "Quantity", width: 120 },
		{ field: "strike_price", headerName: "Strike Price", width: 120 },
		{ field: "right", headerName: "Right", width: 150 },
		{ field: "LTP", headerName: "LTP", width: 120 },
		{
			field: "price",
			headerName: "Price",
			width: 120,
		},
		{ field: "product_type", headerName: "Product Type", width: 120 },
		{ field: "exchange_code", headerName: "Exchange Code", width: 100 },
		{ field: "book_type", headerName: "Book Type", width: 120 },
		{
			field: "brokerage_amount",
			headerName: "Brokerage Amount",
			width: 150,
		},
		{ field: "segment", headerName: "Segment", width: 120 },
		{ field: "settlement_code", headerName: "Settlement Code", width: 150 },
		{ field: "dp_id", headerName: "DP ID", width: 120 },
		{ field: "client_id", headerName: "Client ID", width: 120 },
		{
			field: "eatm_withheld_amount",
			headerName: "EATM Withheld Amount",
			width: 180,
		},
		{
			field: "cash_withheld_amount",
			headerName: "Cash Withheld Amount",
			width: 180,
		},
		{ field: "total_taxes", headerName: "Total Taxes", width: 120 },
	];

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			{rows.length === 0 ? (
				<Typography variant="h6" align="center">
					No data available at the moment.
				</Typography>
			) : (
				<DataGrid
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: pageSize,
							},
						},
						columns: {
							columnVisibilityModel: {
								// Hide columns that are not critical to display
								exchange_code: false,
								product_type: false,
								order_type: false,
								book_type: false,
								brokerage_amount: false,
								segment: false,
								settlement_code: false,
								dp_id: false,
								LTP: false,
								client_id: false,
								eatm_withheld_amount: false,
								cash_withheld_amount: false,
								total_taxes: false,
							},
						},
					}}
					pageSizeOptions={[5, 10, 20]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			)}
		</Box>
	);
};

export default TradeGrid;
