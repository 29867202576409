import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Login from "../pages/Login";
import TopBar from "../components/global/TopBar/TopBar";
import ProtectedRoute from "./ProtectedRoute";
import Trade from "../components/trade/Trade";
import Analysis from "../pages/Analysis/Analysis";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useLogger } from "../contexts/LoggingContext";
import Logger from "../components/logger/Logger";
import OrderAndTrade from "../pages/orderAndTrade/OrderAndTrade";

const AppRoutes: React.FC = () => {
	const { showLogger } = useLogger(); // Get visibility state from context
	const { ws, connect, isConnected } = useWebSocket();

	useEffect(() => {
		if (!ws && isConnected === false) {
			connect();
		}
	}, [ws, isConnected, connect]);

	return (
		<>
			<TopBar />
			<Routes>
				<Route path="/home" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/login" element={<Login />} />
				<Route path="/" />
				<Route path="/trade" element={<ProtectedRoute element={<Trade />} />} />
				<Route path="/analysis" element={<ProtectedRoute element={<Analysis />} />} />
				<Route path="/orderAndTrade" element={<ProtectedRoute element={<OrderAndTrade />} />} />
			</Routes>
			{showLogger && <Logger />}{" "}
		</>
	);
};

export default AppRoutes;
